import React, { useContext } from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { LanguageContext } from 'context'
import { translateData } from 'utils'
import { Button } from 'components'

const TeamStandings = ({ component }) => {
  const { mainTitle, teams, button } = component
  const dictionary = useContext(LanguageContext).staticData
  const teamInfo = translateData(dictionary)
  const currentLang = useContext(LanguageContext).currentLang

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    swipe: false,
    cssEase: 'linear',
    fade: false,
    centerMode: false,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? teams.length - 1 : 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false
  }

  return (
    <section className='team-standings-area'>
      <div className='container'>
        <h1 className='sec-title'>{mainTitle}</h1>
        <div className='teams-wrapper'>
          <Slider {...sliderSettings} className='teams-slider'>
            {teams.map((team) => {
              const { id, teamName, position, teamPoints, carImage } = team
              const image = getImage(carImage)
              return (
                <div key={id} className='team-box'>
                  <div className='image-wrapper'>
                    <GatsbyImage image={image} alt={teamName} className='car-image' />
                    <span className='position'>{position}</span>
                  </div>
                  <div className='content-wrapper'>
                    <h3 className='team-name'>{teamName}</h3>
                    <h2 className='team-points'>
                      <span className='data-name'>{teamInfo['driver-points']}</span>
                      <span className='data-content'>{teamPoints}</span>
                    </h2>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
        <div className='btn-wrapper'>
          <Button btn={button} type={'teamStandingsInfoSMC'} className='btn-primary' />
        </div>
      </div>
    </section>
  )
}

export default TeamStandings
