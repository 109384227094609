import React, { useContext } from 'react';
import { LanguageContext } from 'context';
import Slider from 'react-slick';
import { OfferingCard } from 'components';

const KartingOffering = ({ component, wrapper = '' }) => {
  const { title, cards } = component;
  const currentLang = useContext(LanguageContext).currentLang;

  const rtl_slick = () => {
    return currentLang === 'ar-SA' ? true : false;
  };

  const responsiveSettings = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const defaultSlidesToShow = 5;

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: defaultSlidesToShow,
    slidesToScroll: 1,
    dots: false,
    swipe: true,
    cssEase: 'linear',
    fade: false,
    draggable: true,
    centerMode: false,
    arrows: false,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? cards.length - defaultSlidesToShow : 0,
    responsive: responsiveSettings,
  };
  return (
    <section className='latest-news'>
      <div className='mx-4'>
        <div className='header-wrapper'>
          <div className='sec-title'>
            <h1>{title}</h1>
          </div>
        </div>
      </div>

      <Slider {...sliderSettings} className='latest-news-slider'>
        {cards.map((item) => {
          return <OfferingCard offering={item} key={item.id} />;
        })}
      </Slider>
    </section>
  );
};

export default KartingOffering;
