import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Controller, Scene } from 'react-scrollmagic';

const AboutUs = ({ component }) => {
  const { mainTitle, content1, content2, image1 } = component;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  let contentTextDocument1;
  let contentTextDocument2;
  if (content1) {
    contentTextDocument1 = JSON.parse(content1.raw);
  }

  let blocks1;
  if (contentTextDocument1) {
    blocks1 = documentToReactComponents(contentTextDocument1, options);
  }

  if (content2) {
    contentTextDocument2 = JSON.parse(content2.raw);
  }

  // let blocks2;
  // if (contentTextDocument2) {
  //   blocks2 = documentToReactComponents(contentTextDocument2, options);
  // }

  return (
    <Controller>
      <section className='about-us' id='about-wrapper'>
        <div className='top-part'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-5'>
                {mainTitle && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#about-wrapper' reverse={true}>
                    <h1 className='animate__animated'>{mainTitle}</h1>
                  </Scene>
                )}
                {blocks1 && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#about-wrapper' reverse={true}>
                    <div className='blocks1 animate__animated text-justify'>{blocks1}</div>
                  </Scene>
                )}
              </div>
              <div className='col-lg-7'>
                <div className='image1-wrapper'>
                  <Scene classToggle='animate__fadeInRight' triggerElement='#about-wrapper' reverse={true}>
                    <img
                      style={{
                        width: '100%',
                      }}
                      alt={image1.description}
                      src={image1.file.url}
                    />
                  </Scene>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='bottom-part' id='bottomPart'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-md-6'>
                <div className='image2-wrapper'>
                  <Scene offset={-200} classToggle='animate__fadeInLeft' triggerElement='#bottomPart' reverse={true}>
                    <img
                      alt={image2.description}
                      src={image2.file.url}
                      className='image2 img-fluid animate__animated'
                      loading='lazy'
                    />
                  </Scene>
                </div>
              </div>
              <div className='col-md-6'>
                {blocks2 && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#bottomPart' reverse={true}>
                    <div className='blocks2 animate__animated'>{blocks2}</div>
                  </Scene>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </Controller>
  );
};

export default AboutUs;
