import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { useScreenSize, convertToArabicNumbers } from 'utils';
import { LanguageContext } from 'context';
import pattern from '../../assets/images/wtcr-tyre-pattern.svg';
import whatsapp from '../../assets/images/whatsapp-icon.svg';
import download from '../../assets/images/download-icon.png';
import fullscreen from '../../assets/images/fullscreen-icon.png';

const Magazine = ({ component }) => {
  const currentLang = useContext(LanguageContext).currentLang;

  const screenSize = useScreenSize();
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  // const [currentOrigin, setCurrentOrigin] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const magazineContainerRef = useRef();
  const book = useRef();

  const onFlip = useCallback((e) => {
    setCurrentPage(e.data + 1);
  });

  const nextButtonClick = () => {
    book.current.pageFlip().flipNext();
  };

  const prevButtonClick = () => {
    book.current.pageFlip().flipPrev();
  };

  useEffect(() => {
    if (book) {
      setTotalPages(book.current.pageFlip()?.getPageCount() || component.magazinePages.length);
    }
  }, [book.current]);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    // setCurrentOrigin(window.location.origin);
  }, [screenSize.width, screenSize.height]);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full-screen mode
      const element = magazineContainerRef.current;
      if (element) {
        if (element.requestFullscreen) {
          element.requestFullscreen().then(() => {
            setIsFullScreen(true);
          });
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen().then(() => {
            setIsFullScreen(true);
          });
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen().then(() => {
            setIsFullScreen(true);
          });
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen().then(() => {
            setIsFullScreen(true);
          });
        }
      }
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          setIsFullScreen(false);
        });
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen().then(() => {
          setIsFullScreen(false);
        });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().then(() => {
          setIsFullScreen(false);
        });
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen().then(() => {
          setIsFullScreen(false);
        });
      }
    }
  };

  const handledownloadClick = (event, link) => {
    event.stopPropagation();
    event.preventDefault();
    window.lmSMTObj.track('smcMagazineDowloadClicked', {
      magazineLanguage: currentLang === 'ar-SA' ? 'Arabic' : 'English',
    });
    window.open(link, '_blank');
  };

  useEffect(() => {
    // Listen for fullscreen changes
    const handleFullScreenChange = () => {
      setIsFullScreen(
        !!document.fullscreenElement ||
          !!document.mozFullScreenElement ||
          !!document.webkitFullscreenElement ||
          !!document.msFullscreenElement,
      );
      setCurrentPage(isFullScreen ? currentPage : 1);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    return () => {
      // Remove event listeners when the component unmounts
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('msfullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <section className='drivers-carousel-area magazine'>
      <img className='top-pattern' src={pattern} alt='' />
      {/* <div className='pattern-bg'></div> */}
      <div className='container'>
        <div className='header-wrapper'>
          <div className='sec-title light'>
            <i className='pattern'></i>
            <h1>{component.title}</h1>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='description'>{component.subTitle}</p>
          <div className='magazine-tools'>
            <div className='custom-tooltip' data-tooltip={'Share'}>
              <a
                href={`${
                  isMobile ? 'whatsapp://' : 'https://web.whatsapp.com/'
                }send?text=Checkout the SMC Magazine \n ${component.magazineFile.file.url}`}
                data-action='share/whatsapp/share'
                target='_blank'
                rel="noreferrer"
              >
                <img src={whatsapp} alt='whatsapp' style={{ margin: '0 5px', width: '30px' }} />
              </a>
            </div>
            <div className='custom-tooltip' data-tooltip={'Download'}>
              <a
                href={'#javascript'}
                onClick={(event) => handledownloadClick(event, component.magazineFile.file.url)}
                target='_blank'
                rel='noreferrer'
              >
                <img src={download} alt='download' style={{ width: '30px' }} />
              </a>
            </div>
            <div className='custom-tooltip fullscreen-mode' data-tooltip={'Fullscreen'} onClick={toggleFullScreen}>
              <img src={fullscreen} alt='fullscreen' style={{ width: '30px' }} />
            </div>
          </div>
        </div>
      </div>
      <div
        ref={magazineContainerRef}
        className={`${currentPage === 1 ? 'front-cover' : currentPage === totalPages ? 'back-cover' : ''}`}
      >
        {isMobile ? (
          <HTMLFlipBook
            width={550}
            height={733}
            size='stretch'
            minWidth={315}
            maxWidth={screenSize.height - 100}
            minHeight={420}
            maxHeight={screenSize.height}
            showCover={true}
            onFlip={onFlip}
            maxShadowOpacity={0.5}
            mobileScrollSupport={true}
            ref={book}
            className='demo-book overflow-hidden mx-auto'
          >
            {component.magazinePages.map((image, index, array) => {
              let renderIndex;
              if (index === 0) {
                renderIndex = index;
              } else if (index === array.length - 1) {
                renderIndex = index;
              } else if (index % 2 === 0) {
                renderIndex = index - 1;
              } else {
                renderIndex = index + 1;
              }
              return (
                <div key={index} className='page'>
                  <img
                    src={
                      screenSize.width < 630 && currentLang === 'ar-SA'
                        ? array[renderIndex].gatsbyImageData.images.fallback.src
                        : image.gatsbyImageData.images.fallback.src
                    }
                    alt={`Page ${index + 1}`}
                    className='magazine-img'
                  />
                </div>
              );
            })}
          </HTMLFlipBook>
        ) : (
          <HTMLFlipBook
            key={`${screenSize.height}-${isFullScreen}`}
            width={screenSize.height - 100}
            height={screenSize.height}
            size='stretch'
            minWidth={315}
            maxWidth={screenSize.height - 100}
            minHeight={420}
            maxHeight={screenSize.height}
            showCover={true}
            onFlip={onFlip}
            maxShadowOpacity={0.5}
            mobileScrollSupport={true}
            ref={book}
            className='demo-book overflow-hidden mx-auto'
          >
            {component.magazinePages.map((image, index, array) => {
              let renderIndex;
              if (index === 0) {
                renderIndex = index;
              } else if (index === array.length - 1) {
                renderIndex = index;
              } else if (index % 2 === 0) {
                renderIndex = index - 1;
              } else {
                renderIndex = index + 1;
              }
              return (
                <div key={index} className='page'>
                  <img
                    src={
                      screenSize.width < 630 && currentLang === 'ar-SA'
                        ? array[renderIndex].gatsbyImageData.images.fallback.src
                        : image.gatsbyImageData.images.fallback.src
                    }
                    alt={`Page ${index + 1}`}
                    className='magazine-img'
                  />
                </div>
              );
            })}
          </HTMLFlipBook>
        )}
      </div>
      <div className='container book-navigation'>
        <div className='btn-wrapper'>
          <button
            className='btn-primary next'
            onClick={currentLang === 'ar-SA' ? prevButtonClick : nextButtonClick}
            disabled={currentLang === 'ar-SA' ? currentPage === 1 : currentPage === totalPages}
          >
            <i className='right-arrow-icon'></i>
          </button>
          <div className='pagination'>
            <span>
              {currentLang === 'ar-SA' && currentPage != null ? convertToArabicNumbers(currentPage) : currentPage}
            </span>
            <span className='seperator'>/</span>
            <span>
              {currentLang === 'ar-SA' && totalPages != null ? convertToArabicNumbers(totalPages) : totalPages}
            </span>
          </div>
          <button
            className='btn-primary prev'
            onClick={currentLang === 'ar-SA' ? nextButtonClick : prevButtonClick}
            disabled={currentLang === 'ar-SA' ? currentPage === totalPages : currentPage === 1}
          >
            <i className='right-arrow-icon'></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Magazine;
