import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from 'context'
import { useCookie } from 'utils'
import favicon from '../../assets/images/favicon.svg'

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulWebSite {
      id
      title
      gtCode
    }
  }
`

const SeoComponent = ({ page }) => {
  const currentLang = useContext(LanguageContext).currentLang
  const { site, contentfulWebSite } = useStaticQuery(query)
  const page_language = currentLang.split('-')[0]
  const { gtCode } = contentfulWebSite
  const { title, seoDescription, seoTitle, slug } = page

  const metaTilte = seoTitle || `${title} | ${site.siteMetadata.title}`
  const metaDescription = seoDescription?.seoDescription || site.siteMetadata.description

  const ga_id_cookie = useCookie(['_gid'])
  const [cookie] = ga_id_cookie

  const ga_id = cookie

  return (
    <>
      <noscript key='googleTagmanager'>
        {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=${gtCode}"
            height="0"
            width="0"
            style="display:none;visibility:hidden"></iframe>`}
      </noscript>
      <Helmet title={metaTilte} meta={[{ name: `description`, content: metaDescription }]}>
        <link rel='icon' href={favicon} />
        <script>
          {`
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push(
              { 
                "gtm.start": new Date().getTime(),
                event: "gtm.js"
              }
            );
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "${gtCode}");
        `}
        </script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'page_url': window.location.href,
            'page_title': '${title}', 
            'language': '${page_language}', 
            'ga_id': '${ga_id}', //Unique GA ID from _gid cookie eg: GA1.2.49688231.1623161853
          });
        `}
        </script>
        <script type='text/javascript'>
          {`
        window.lmSMTObj = window.lmSMTObj || [];
        var methods = [
            "init",
            "page",
            "track",
            "identify"
        ]
        for (var i=0; i<methods.length; i++) {
            lmSMTObj[methods[i]] = function(methodName){
                return function(){
                    lmSMTObj.push([methodName].concat(Array.prototype.slice.call(arguments)));
                };
            }(methods[i]);
        }
        lmSMTObj.init("8b5t8xzrtw6fm8fwk7rw");
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.src = "//cdn25.lemnisk.co/ssp/st/6155.js";
        var b = document.getElementsByTagName("script")[0];
        b.parentNode.insertBefore(a, b);
        `}
        </script>
        <script type='text/javascript'>
          {`
         lmSMTObj.page('${title}', {
          "path": '${slug}',
          "language": '${page_language}', 
          "referrer": "https://www.google.com/",
          "title": '${title}',
          "url": window.location.href,
          "search": "",
        })`}
        </script>
      </Helmet>
    </>
  )
}

export default SeoComponent
