import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const CornicheCircuit = ({ component }) => {
  const { mainTitle, description, backgroundImage } = component;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  let descriptionText;
  if (description) {
    descriptionText = JSON.parse(description.raw);
  }

  let blocks;
  if (descriptionText) {
    blocks = documentToReactComponents(descriptionText, options);
  }

  return (
    <section className='corniche-circuit-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='content-box'>
              {<h1 className='main-title'>{mainTitle}</h1>}
              {blocks && <div className='description'>{blocks}</div>}
            </div>
          </div>
        </div>
      </div>
      <img alt='' src={backgroundImage.file.url} className='bg-img' />
    </section>
  );
};

export default CornicheCircuit;
