import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const AboutKarting = ({ component }) => {
  const { mainTitle, backgroundImage, content } = component;
  const imageBg = getImage(backgroundImage);
  const bgImage = convertToBgImage(imageBg);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  let contentText;
  if (content) {
    contentText = JSON.parse(content.raw);
  }

  let blocks;
  if (contentText) {
    blocks = documentToReactComponents(contentText, options);
  }

  return (
    <BackgroundImage className='about-karting' Tag='div' {...bgImage}>
      <div className='overlay'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 offset-md-6'>
              <h1 className='main-title'>{mainTitle}</h1>
              {blocks}
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default AboutKarting;
