import React from 'react';
import { Component } from '..';

const ColumnsLayout = ({ component, wrapper = '' }) => {
  const { columns } = component;
  return (
    <>
      <div className={`${wrapper}`}>
        <div className='row'>
          {columns.map((column) => {
            return (
              <div
                className={`column-layout col-md-${column.columnSize} ${
                  column.verticalAlignment ? column.verticalAlignment.toLowerCase() + '-alignment' : ''
                }`}
                key={column.id}
              >
                {column.components &&
                  column.components.map((component) => {
                    return <Component component={component} key={component.id} wrapper='wrapper' />;
                  })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ColumnsLayout;
