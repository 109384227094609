import React from 'react';

const OfferingCard = ({ offering }) => {
  const { eventName, eventDescription, eventMainImage } = offering;
  const width = eventMainImage?.file?.fileName === 'brew.svg' ? 140 : 75;
  const height = 75;

  return (
    <div className='news-article offering-card p-3'>
      <div class="col-12 center-align">
        <img alt='hotspot-point' src={eventMainImage?.file?.url} width={width} height={height} />
      </div>
      <div className='content-wrapper'>
        <h1 className='title font-weight-bold center-align'>
          {eventName}
        </h1>
        <p className="article-description">{eventDescription.eventDescription}</p>
      </div>
    </div>
  );
};

export default OfferingCard;
