import React, { useEffect } from 'react';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const CustomHtml = ({ component }) => {
  const isBrowser = () => typeof window !== 'undefined';
  const {  title, ytVideoId } = component;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://seekbeak.com/js/sbembed.js';
    script.async = true;
    document.body.appendChild(script);

    if (isBrowser && ytVideoId != null) {
      async function delayedplayer() {
        if (typeof window.YT == 'undefined' || typeof window.YT.Player == 'undefined') {
          let tag = document.createElement('script');
          tag.src = '//www.youtube.com/iframe_api';
          let firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
          firstScriptTag.async = true;
        }

        while (typeof window.YT == 'undefined' || typeof window.YT.Player == 'undefined') {
          await sleep(1000);
        }

        function onYouTubePlayerAPIReady() {
          window.YT.ready(function () {
            const player = new window.YT.Player('ytplayer', {
              videoId: ytVideoId,
              playerVars: {
                showinfo: 0,
                controls: 0,
                enablejsapi: 1,
                rel: 0,
                autoplay: 1,
                playsinline: 1,
                loop: 1,
                playlist: ytVideoId,
                widgetid: 1,
                modestbranding: 1,
              },
              events: {
                onReady: onPlayerReady,
              },
            });
          });
        }

        function onPlayerReady(event) {
          event.target.mute();
          event.target.playVideo();
        }

        onYouTubePlayerAPIReady();
      }

      delayedplayer();
    }


    return () => {
      document.body.removeChild(script);
    };
  }, [ytVideoId]);

  return (
    <section style={{ marginBottom: '100px' }}>
      <div className='sec-title container' style={{ marginBottom: '50px' }}>
        <i className='pattern'></i>
        <h1>{title}</h1>
        {/* {description && (
          <p className='virtual-map-description'>{description.description}</p>
        )} */}
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: html.html }} /> */}
      <div id='ytplayer' style={{
      width: '100%',
      height: '700px',
    }}></div>
    </section>
  );
};

export default CustomHtml;
