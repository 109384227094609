import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { ResellerCard, PromoCard, StacklaWidget, ZoomedImage } from 'components';
import JourneyToJccFit from '../journeyToJccFit';
import JCCFitRegistationBlock from '../jccRegistrationBlock';

const BLOCK_TYPES = {
  resellerCard: 'Reseller Card',
  imageAndHeader: 'Image And Header',
  stacklaWidget: 'Stackla Widget',
  zoomedImage: 'Zoomed Image',
  jccFitJourney: 'Journey to JCC Fit',
  bannerTitle: 'Banner Title',
  jccRegistrationBlock: 'JCC Fit Registration Block',
};

const ContentBlock = ({ component, wrapper = '' }) => {
  const { content, blockType, subTitle, mainTitle, contentImages, cssWrapper, resellerAddress } = component;
  let contentTextDocument;
  if (content) {
    contentTextDocument = JSON.parse(content.raw);
  }

  const lists = {
    [BLOCKS.UL_LIST]: (node, children) => <ul className='ul-first-level'>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol className='ol-first-level'>{children}</ol>,
  };

  const nestedLists = {
    [BLOCKS.UL_LIST]: (node, children) => <ul className='ul-second-level'>{children}</ul>,
  };

  const options = {
    renderNode: {
      ...lists,
      [BLOCKS.HEADING_1]: (node, children) => {
        let returnedValue;
        if (contentImages && contentImages.length > 0) {
          contentImages.forEach((img, index) => {
            const image = getImage(img);
            if (children[0].trim().includes(`{{${index + 1}}}`)) {
              returnedValue = (
                <GatsbyImage
                  image={image}
                  alt={img.description || ''}
                  className='content-image'
                  imgClassName='content-img'
                />
              );
            }
          });
        }
        return returnedValue || <h1>{children[0]}</h1>;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const normalisedListChildren = documentToReactComponents(node, {
          renderNode: {
            ...nestedLists,
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
          },
        });
        return normalisedListChildren;
      },
    },
  };

  let blocks;
  if (contentTextDocument) {
    blocks = documentToReactComponents(contentTextDocument, options);
  }

  if (blockType === BLOCK_TYPES.resellerCard) {
    return (
      <ResellerCard
        resellerAddress={resellerAddress}
        cssWrapper={cssWrapper}
        subTitle={subTitle}
        mainTitle={mainTitle}
      />
    );
  }
  if (blockType === BLOCK_TYPES.imageAndHeader) {
    return <PromoCard resellerAddress={resellerAddress} subTitle={subTitle} mainTitle={mainTitle} blocks={blocks} />;
  }
  if (blockType === BLOCK_TYPES.stacklaWidget) {
    return <StacklaWidget subTitle={subTitle} mainTitle={mainTitle} />;
  }
  if (blockType === BLOCK_TYPES.zoomedImage) {
    return <ZoomedImage contentImages={contentImages} />;
  }
  if (blockType === BLOCK_TYPES.jccFitJourney) {
    return <JourneyToJccFit component={component}/>;
  }
  if (blockType === BLOCK_TYPES.jccRegistrationBlock) {
    return <JCCFitRegistationBlock mainTitle={mainTitle}/>;
  }
  if(blockType === BLOCK_TYPES.bannerTitle){
    return (
      <div className='content-container d-block banner-title-section'>
        <div className='container'>
          <h1 className='l-transp'>{mainTitle}</h1>
        </div>
      </div>
    )
  }
  return (
    <div
      id={wrapper && wrapper.split(' ')[0]}
      className={`content-block-wrapper container${wrapper ? ' ' + wrapper : ''}`}
    >
      <div className='row'>
        <div className='container-fluid'>
          <div className={`row ${cssWrapper}`}>
            <div className='col-sm-12'>
              {subTitle && <h5 className='sub-title'>{subTitle}</h5>}
              {mainTitle && (
                <div className='sec-title'>
                  <i className='pattern'></i>
                  <h1>{mainTitle}</h1>
                </div>
              )}
              {blocks && blocks}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBlock;
