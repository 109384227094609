import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { LanguageContext } from 'context';

const ArticleSharePage = ({ articles, pageContext }) => {
  let localePath;
  const currentLang = useContext(LanguageContext).currentLang;
  
  if (pageContext.nodeLocale === 'ar-SA') {
    localePath = '';
  } else {
    localePath = 'en/';
  }

  const sortArticles = [...articles.articleCategory.article].sort((a, b) =>
    new Date(b.articleDate) < new Date(a.articleDate) ? 1 : -1,
  );
  const articleIndex = sortArticles.findIndex((element) => element.slug === pageContext.news);
  const articleNewsCategory = pageContext.slug.split('/')[0];

  return (
    <div className='container-fluid'>
      <div className='container article-share-block'>
        {/*<div className="row">
           <div className="col-md-6 col-sm-12">
            <div className="share-block">
              Share{" "}
              <TwitterShareButton className="share-button" url={shareUrl} title={pageContext.news}>
                <i className="btn-square btn-square-twitter"></i>
              </TwitterShareButton>
              <FacebookShareButton
                className="share-button"
                url={shareUrl}
                hashtag={`#${pageContext.news}`}
              >
                <i className="btn-square btn-square-facebook"></i>
              </FacebookShareButton>
            </div>
          </div> */}
        {/* <div className="col-md-6 col-sm-12"> */}
        <div className='article-buttons'>
          {articleIndex < sortArticles.length - 1 && (
            <Link
              className='btn-primary'
              to={`/${localePath}${articleNewsCategory}/${sortArticles[articleIndex + 1].slug}`}
            >
              <span>{ currentLang === 'ar-SA' ? 'المقال السابق' : 'Previous Article' }</span>
            </Link>
          )}
          {articleIndex > 0 && (
            // TODO: TRANSLATE Previous Article AND Next Article
            <Link
              className='btn-primary'
              to={`/${localePath}${articleNewsCategory}/${sortArticles[articleIndex - 1].slug}`}
            >
              <span>{ currentLang === 'ar-SA' ? 'المقال التالي' : 'Next Article' }</span>
            </Link>
          )}
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default ArticleSharePage;
