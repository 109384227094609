import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const EventsGrid = ({ component, wrapper = '' }) => {
  const { title, events, button } = component;
  // const currentLang = useContext(LanguageContext).currentLang;

  const scrollDown = () => {
    window.scrollBy({
      top: 1500,
      behavior: 'smooth'
    });
  }
  return (
    <>
      {wrapper === 'jcc-fit-events' ? (
        <section className='jcc-fit-events-grid'>
          <div className='container'>
            <h1 class='title l-transp'>{title}</h1>
            <div className='row'>
              {events.length > 0 &&
                events.map((event) => {
                  // const startDate = formatEventDateWithTime(event.startDate, currentLang);
                  // const endDate = formatEventDateWithTime(event.endDate, currentLang);
                  const eventImage = getImage(event.eventMainImage);
                  return (
                    <div className='col-md-6 col-lg-4 col-xl-3' key={event.id}>
                      <div className='event-card h-100'>
                        <GatsbyImage image={eventImage} alt={event.eventName} />
                        <div className='card-content'>
                          <h3 className='event-title'>{event.eventName}</h3>
                          {/* <div className='event-date'>
                          {startDate.hours!=12 && startDate.ampm!='am'? (
                            <span className='time'>
                              {currentLang === 'ar-SA' 
                                ? <span>{startDate.hours}:{startDate.minutes} {startDate.ampm} - {endDate.hours}:{endDate.minutes} {endDate.ampm}</span>
                                : <span>{startDate.hours}:{startDate.minutes} {startDate.ampm} - {endDate.hours}:{endDate.minutes} {endDate.ampm}</span>
                              }
                              </span>
                          ):(
                            <span className='time'> </span>
                          )}
                            <span className='date'>{startDate.day} {startDate.month} {startDate.year}</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      ) : (
        // <section className='events-grid'>
        //   <div className='container'>
        //     <div className='row'>
        //       {events.length > 0 &&
        //         events.map((event) => {
        //           return (
        //             <div className='col-md-6' key={event.id}>
        //               <EventItem event={event} />
        //             </div>
        //           );
        //         })}
        //     </div>
        //     <div className='btn-wrapper'>
        //       <Button type='eventsClickedSMC' btn={button} className='btn-primary' />
        //     </div>
        //   </div>
        // </section>
        <section className='karting-track-stats px-5 py-5'>
          <h1 className='relative mb-16 text-center text-uppercase text-white font-weight-bold'>
            {title}
          </h1>
          <div className='d-flex justify-content-center text-center mt-5'>
            {events.map((event) => {
              const eventImage = event?.eventMainImage?.file?.url;
              return (
                <div className='d-flex flex-column align-items-center px-3 px-sm-5 mr-3 mr-sm-4' key={event.id}>
                  {/* <GatsbyImage image={eventImage} alt={event.eventName} /> */}
                  <img alt='hotspot-point' src={eventImage} width={70} height={70} />
                  <span className='mt-4 font-weight-bold text-uppercase text-white h4'>
                    {event.eventName}
                  </span>
                </div>
              );
            })}
          </div>



          <div className='d-flex justify-content-center mt-5'>
            <div className='btn-wrapper'>
              <button className='btn-book-karting' onClick={scrollDown}>
                <span>{button?.label}</span>
                <Arrow />
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const Arrow = () => {
  return (
    <svg
      className='right-arrow'
      width='12'
      height='12'
      viewBox='0 0 20 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_461_6657)'>
        <path
          d='M18.9342 8.88539L10.6421 0.426804C10.1156 -0.118912 9.32583 -0.118912 8.79935 0.426804C8.27286 0.972519 8.27286 1.79109 8.79935 2.33681L15.9069 9.56754H1.42852C0.638792 9.56754 0.112305 10.2497 0.112305 10.9318C0.112305 11.614 0.638792 12.2961 1.42852 12.2961H15.7753L8.79935 19.5268C8.27286 20.0726 8.27286 20.8911 8.79935 21.4368C9.06259 21.7097 9.45745 21.8461 9.7207 21.8461C9.98394 21.8461 10.3788 21.8461 10.6421 21.5733L18.8026 13.1147C19.3291 12.569 19.5923 11.8868 19.5923 11.0683C19.5923 10.2497 19.4607 9.43111 18.9342 8.88539Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_461_6657'>
          <rect width='19.6117' height='21.965' fill='white' transform='translate(0.112305 0.0175171)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EventsGrid;
