import React from 'react';
import { ColumnsLayout } from 'components';
import pattern from '../../assets/images/karting-intro.svg';

const KartingIntroduction = ({ component }) => {
  const { mainTitle, lastTitle, columns } = component;

  return (
    <section className='karting-introduction'>
      <img className='top-pattern' src={pattern} alt='' />
      <div className='container'>
        <div className='sec-title light'>
          <i className='pattern'></i>
          <h1>{mainTitle}</h1>
        </div>
        {columns[0] && <ColumnsLayout component={columns[0]} />}
        <div className='last-title'>{lastTitle && <h1>{lastTitle}</h1>}</div>
      </div>
    </section>
  );
};

export default KartingIntroduction;
