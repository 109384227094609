const convertToArabicNumbers = (englishNumber) => {
    const arabicNumbers = {
      '0': '٠',
      '1': '١',
      '2': '٢',
      '3': '٣',
      '4': '٤',
      '5': '٥',
      '6': '٦',
      '7': '٧',
      '8': '٨',
      '9': '٩',
  };
  const englishString = englishNumber.toString(); // Convert to string
  const arabicNumber = englishString
    .split('')
    .map((digit) => (arabicNumbers[digit] ? arabicNumbers[digit] : digit))
    .join('');

  return arabicNumber;
};

export default convertToArabicNumbers;