import React from 'react';
import { Component } from '../component/';

const Sections = ({ sections = [] }) => {
  return (
    <>
      {sections?.map((section) => {
        return (
          <div key={section.title} className={section.sectionWrapper}>
            {section.components?.map((component) => {
              return (
                <Component wrapper={section.cssWrapper} component={component} key={component.title || component.id} />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default Sections;
