import React from 'react';

const KartingBooking = ({ component, wrapper = '' }) => {
  const { srcUrl } = component;
  return (
    <section>
      <iframe
          id='booking'
          src={srcUrl}
          width='100%'
          height='1100'
          title='Booking Content'
        ></iframe>
    </section>
  );
};

export default KartingBooking;
