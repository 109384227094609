const translateData = (dictionary) => {
  const translatedDictionary = {};
  dictionary.map((item) => {
    return item.contentItems.map((item) => {
      return (translatedDictionary[item.keyString] = item.stringValue.stringValue);
    });
  });

  return translatedDictionary;
};

export default translateData;
