import React, { useContext } from 'react';
import { LanguageContext } from 'context';
import { translateData } from 'utils';
const widgetIcon = require('assets/images/ticket-icon.svg');

const TicketWidget = () => {
  const currentLang = useContext(LanguageContext).currentLang;
  const dictionary = useContext(LanguageContext).staticData;
  const linksInfo = translateData(dictionary);

  return (
    <div id='ticket-widget'>
      <a
        href={
          currentLang === 'ar-SA' ? 'https://tickets.saudiarabiangp.com/ar' : 'https://tickets.saudiarabiangp.com/en'
        }
      >
        {linksInfo['link-buy-tickets']}
      </a>
      <figure>
        <img src={widgetIcon.default} alt='buy tickets' />
      </figure>
    </div>
  );
};

export default TicketWidget;
