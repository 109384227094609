import React from 'react';

const TopVideoBanner = ({ component }) => {
  const { video, videoTitle } = component;

  return (
    <section className='video-banner-wrapper'>
      {videoTitle && (
        <div className='sec-title container' style={{ marginBottom: '50px' }}>
          <i className='pattern'></i>
          <h1>{videoTitle}</h1>
        </div>
      )}

      <video loop autoPlay muted className='video-background'>
        <source src={video.file.url} type='video/mp4' />
      </video>
    </section>
  );
};

export default TopVideoBanner;
