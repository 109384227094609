import React, { useContext } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Slider from 'react-slick';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { LanguageContext } from 'context';

const GetZone = ({ component }) => {
  const { mainTitle, description, sliderImages } = component;
  const currentLang = useContext(LanguageContext).currentLang;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  let descriptionText;
  if (description) {
    descriptionText = JSON.parse(description.raw);
  }

  let blocks;
  if (descriptionText) {
    blocks = documentToReactComponents(descriptionText, options);
  }

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button type='button' data-role='none' className={className} onClick={onClick}>
        <i className='right-arrow-icon'></i>
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button type='button' data-role='none' className={className} onClick={onClick}>
        <i className='right-arrow-icon'></i>
      </button>
    );
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    nextArrow: <NextArrow className='next-arrow' />,
    prevArrow: <PrevArrow className='prev-arrow' />,
    rtl: rtl_slick(),

    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false;
  }

  return (
    <Controller>
      <section className='get-zone-area' id='zone-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#zone-wrapper' reverse={true}>
                <div className='content-box animate__animated'>
                  <h1 className='main-title'>{mainTitle}</h1>
                  {description && <div className='description'>{blocks}</div>}
                </div>
              </Scene>
            </div>
          </div>
        </div>
        <Slider {...sliderSettings} className='zone-slider'>
          {sliderImages.map((item) => {
            const galleryImage = getImage(item);
            const bgImage = convertToBgImage(galleryImage);
            return (
              <BackgroundImage
                key={item.id}
                className='gallery-photo'
                Tag='div'
                {...bgImage}
                preserveStackingContext
              ></BackgroundImage>
            );
          })}
        </Slider>
      </section>
    </Controller>
  );
};

export default GetZone;
