import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ArriveAndDriveCard = ({item}) => {
  const { components } = item;
  const dataObj = (Array.isArray(components) && components.length > 0) ? components[0] : undefined;
  let contentTextDocument;
  if (dataObj?.content) {
    contentTextDocument = JSON.parse(dataObj?.content?.raw);
  }
  const paragraphs = documentToReactComponents(contentTextDocument);
  const imageToShow = dataObj?.contentImages[0]?.file?.url;

  return (
    <div className='news-article arrive-card'>
      <div className='content-wrappers'>
      <img alt='hotspot-point' src={imageToShow} width={'100%'} height={'100%'} />
        <h1 className='title font-weight-bold p-3'>
          {dataObj?.mainTitle}
        </h1>
        <p className="article-description">{paragraphs}</p>
      </div>
    </div>
  );
};

export default ArriveAndDriveCard;
