import React, { useState, useContext, useRef} from 'react';
import successIcon from '../../assets/images/success-icon.svg';
import { LanguageContext } from 'context';
import { useForm, Controller } from "react-hook-form";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const JccFitRegisterForm = ({component}) => {
    const currentLang = useContext(LanguageContext).currentLang;
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false)
    const successMesssgeRef = useRef(null)
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm()
    const watchActivity = watch("activity");
    const dateOfBirth = watch('dateOfBirth');

    const formatDate = (date) => {
        if (date instanceof Date) {
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }
        return '';
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const activities = data.activity.join(', ');
        const birthDay = formatDate(data.dateOfBirth)
        const formData = new FormData();

        const fields = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            dateOfBirth: birthDay,
            activity: activities
        };

        for (const [key, value] of Object.entries(fields)) {
            formData.append(key, value);
        }

        const url = `https://script.google.com/macros/s/AKfycbysLCLoh_j4-PaByyCv_4zlp13y43f1d_6DCdWtXxGtVOLKp8wwxa4RAdgrG5DxkIcy/exec`;
        try{
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                redirect: 'follow'
            });
            const res = await response.json();
            if(res.success){
                setSuccess(true)
                setLoading(false)
                window.scrollTo(0, successMesssgeRef.current.offsetTop);
            }
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <section className='jcc-register-form-area'>
            <div className='container'>
                <h1 class="l-transp">{currentLang === 'en-US'? 'Register for JCC fitness activities.' : 'سجل في أنشطة JCC fitness'}</h1>
                {!success && (
                    <form onSubmit={handleSubmit(onSubmit)} className='custom-form'>
                        <div>
                            <div className='d-flex items-center justify-content-between align-items-center'>
                                <h3 className='subtitle'>{currentLang === 'en-US'? 'Fill out your personal info to register': 'ادخل بياناتك الشخصية للتسجيل'}</h3>
                                {/* <span className='steps'>step <span className='active'>1</span> of 3</span> */}
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                        type='text'
                                        className={`form-input ${errors.name? 'error': ''}`}
                                        placeholder={currentLang === 'en-US'?'Name*' : '*الاسم'}
                                        {...register("name", { required: true })} 
                                        />
                                        {errors.name && <span className="error-message">Name is required</span>}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                        type='text'
                                        className={`form-input ${errors.email? 'error': ''}`}
                                        placeholder={currentLang === 'en-US'?'Email*' : '*البريد الإلكتروني'}
                                        {...register("email", 
                                            { required: 'Please enter a valid email',
                                                pattern: {
                                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                    message: 'Please enter a valid email',
                                                }, 
                                            })} 
                                        />
                                        {errors.email && errors.email.type==='required' && 
                                            <span className="error-message">Email is required</span>
                                        }
                                        {errors.email && errors.email.type==='pattern' && 
                                            <span className="error-message">Please enter a valid email</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                        type='number'
                                        className={`form-input ${errors.phone? 'error': ''}`}
                                        placeholder={currentLang === 'en-US'?'Phone*' : '*رقم الهاتف'}
                                        {...register("phone", { required: true,
                                            pattern: {
                                                value: /^\d{9,14}$/,
                                                message: "Please enter a valid phone number (min 10 digits)"
                                            }
                                         })} 
                                        />
                                        {errors.phone && errors.phone.type==='required' && 
                                            <span className="error-message">Phone number is required</span>
                                        }
                                        {errors.phone && errors.phone.type==='pattern' && 
                                            <span className="error-message">Please enter a valid phone number (min 10 digits)</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        {/* <input
                                            type='text'
                                            className={`form-input ${errors.dateOfBirth? 'error': ''}`}
                                            placeholder={currentLang === 'en-US'?'Birthday*' : '*تاريخ الميلاد'}
                                            id="date-input"
                                            {...register("dateOfBirth", { required: true, 
                                                max: {
                                                    value: '2023-01-01',
                                                    message: 'Please enter a valid birth day',
                                                }, 
                                             })} 
                                        /> */}
                                         {/* {errors.dateOfBirth && errors.dateOfBirth.type==="inValidDate" && 
                                            <span className="error-message">Please enter a valid birthday</span>
                                        }
                                        {errors.dateOfBirth && errors.dateOfBirth.type==="required" && 
                                            <span className="error-message">Birthday is required</span>
                                        } */}
                                        <Controller
                                            name="dateOfBirth"
                                            control={control}
                                            rules={{
                                                required: 'Birthday is required',
                                                max: {
                                                  value: new Date(), // Set the maximum date (e.g., today)
                                                  message: 'Date cannot be in the future',
                                                },
                                            }}
                                            render={({ field }) => (
                                            <DatePicker {...field} 
                                                selected={dateOfBirth} 
                                                className={`form-input ${errors.dateOfBirth? 'error': ''}`}
                                                placeholderText="Birthday*"
                                                showYearDropdown
                                                showMonthDropdown
                                                scrollableMonthYearDropdown
                                                yearDropdownItemNumber={100}
                                                minDate={new Date(1900, 0, 1)}
                                                maxDate={new Date()}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                            )}
                                        />
                                        <div>
                                            {errors.dateOfBirth && errors.dateOfBirth.type==="max" && 
                                                <span className="error-message">Birthday cannot be the future date</span>
                                            }
                                            {errors.dateOfBirth && errors.dateOfBirth.type==="required" && 
                                                <span className="error-message">Birthday is required</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-12 d-flex justify-content-end mt-3'>
                                    <button type='submit' className='btn-primary form-btn'>
                                        <span>Next</span> 
                                        <i className='right-arrow-icon'></i>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className='d-flex items-center justify-content-between align-items-center'>
                                <h3 className='subtitle'>{currentLang === 'en-US'? 'Select the activity you wish to register for:' : 'حدد النشاط الذي ترغب التسجيل فيه'}</h3>
                                {/* <span className='steps'>step <span className='active'>2</span> of 3</span> */}
                            </div>
                            
                            <div className='row form-group'>
                                {component.activities.map(activity=>{
                                    return(
                                        <div className='col-12'>
                                            <label className={`radio-container ${watchActivity === activity ? 'active' : ''}`}>
                                                <div className='d-flex justify-content-between align-items-center h-100'>
                                                <span className='label'>{activity}</span>
                                                <input 
                                                    type="checkbox" 
                                                    name="activity" 
                                                    value={activity}
                                                    {...register("activity", { required: true })}/>
                                                <span class="checkmark"></span>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                                {errors.activity && <div className='col-md-12'><span className="error-message">Please select activities</span></div>}
                                {/* <div className='col-12'>
                                    <label className={`radio-container ${watchActivity === 'Running' ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-between align-items-center h-100'>
                                        <span className='label'>Running</span>
                                        <input 
                                            type="radio" 
                                            name="activity" 
                                            value="Running"
                                            {...register("activity", { required: true })}/>
                                        <span class="checkmark"></span>
                                        </div>
                                    </label>
                                </div>
                                <div className='col-12'>
                                    <label className={`radio-container ${watchActivity === 'Biking' ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-between align-items-center h-100'>
                                        <span className='label'>Biking</span>
                                        <input 
                                            type="radio" 
                                            name="activity" 
                                            value="Biking"
                                            {...register("activity", { required: true })}/>
                                        <span class="checkmark"></span>
                                        </div>
                                    </label>
                                </div>
                                <div className='col-12'>
                                    <label className={`radio-container ${watchActivity === 'Roller Skating' ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-between align-items-center h-100'>
                                        <span className='label'>Roller Skating</span>
                                        <input 
                                            type="radio" 
                                            name="activity" 
                                            value="Roller Skating"
                                            {...register("activity", { required: true })}/>
                                        <span class="checkmark"></span>
                                        </div>
                                    </label>
                                </div>
                                <div className='col-12'>
                                    <label className={`radio-container ${watchActivity === 'Family Sport' ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-between align-items-center h-100'>
                                        <span className='label'>Family Sport</span>
                                        <input 
                                            type="radio" 
                                            name="activity" 
                                            value="Family Sport"
                                            {...register("activity", { required: true })}/>
                                        <span class="checkmark"></span>
                                        </div>
                                    </label>
                                    {errors.activity && <span className="error-message">Please select the activity</span>}
                                </div> */}
                                {/* <div className='col-12 d-flex justify-content-end mt-3'>
                                    <button type='submit' className='btn-primary form-btn'>
                                        <span>Next</span> 
                                        <i className='right-arrow-icon'></i>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className='d-flex items-center justify-content-between align-items-center'>
                                <h3 className='subtitle'>{currentLang === 'en-US'? 'safety waiver form' : 'استمارة الإعفاء من المسؤولية'}</h3>
                                {/* <span className='steps'>step <span className='active'>3</span> of 3</span> */}
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className='waiver-form-container'>
                                        <div className='waiver-form-content custom-scroll'>
                                            {currentLang === 'en-US'?(
                                                <div>
                                                    <p>I, the undersigned, understand, agree, and acknowledge that entering and using the Jeddah Corniche Circuit (JCC) and associated activities is dangerous and that there is a possibility of injury, death, or property damage causing accident or loss and that my presence at the Jeddah Corniche Circuit is voluntary It is at my own risk and that I am liable for all risks arising. I, the undersigned, agree to waive all my legal rights and take full responsibility for my actions in all events to be held at the Jeddah Corniche Circuit (JCC) and to attend the events at the Jeddah Corniche Circuit (JCC) and all other related facilities, and I acknowledge that Saudi Motorsports Company (SMC) will not be liable for any injuries that occur inside the circuit or on its facilities.</p>
                                                    <p>I, the undersigned, declare that I am solely responsible for all risks of personal injury, property damage, or other adverse consequences arising from the use, in any way, of equipment, devices, facilities, or services owned, operated, or authorized by The Circuit, including by way of example including without limitation, the use of the track, the racing cars (including the racing cars of the Jeddah Corniche Circuit (JCC) and the Saudi Motorsports Company (SMC), or from any competitions, entertainment, exercises, or any operations or activities conducted or organized either by the Circuit or other organizations. I hereby warrant that I will release Saudi Motorsports Company (SMC) and its effective controller, subsidiaries or other subsidiaries (including any successor or heir) ) and its shareholders, directors, officers, members, employees, and representatives from any liability or claims, demands or cause of action that I may make for injuries or damages arising out of my participation at Jeddah Corniche Circuit, even if due to negligence on their part. I acknowledge that Saudi Motorsports Company (SMC) shall not be liable for any direct, indirect, consequential, special, exemplary, or other damages arising.</p>
                                                    <ul>
                                                        <li>I acknowledge and agree that I have carefully read this release and fully understand that it is a release of all liability and a waiver of any right that I may have to bring a legal action or assert a claim for injury or loss of any kind against the releases. if any attempt for a claim is made, I understand I will be responsible for all defense costs incurred by the releases.</li>
                                                    </ul>
                                                </div>
                                            ): (
                                                <div>
                                                    <p>أنا الموقع أدناه ، أفهم وأوافق، وأقر أن بدخول حلبة كورنيش جدة ( JCC) واستخدام حلبة كورنيش جدة والأنشطة المرتبطة بها يعد أمرًا خطيرًا وأن هناك احتمالًا لحدوث إصابة أو وفاة أو تلف في الممتلكات يتسبب في وقوع حوادث أو خسارة، وأن تواجدي في حلبة كورنيش جدة طوعي وعلى مسؤوليتي الخاصة وحدي وأنني أتحمل جميع المخاطر الناجمة عنها. بذلك، أنا الموقع أدناه أوافق على التنازل عن جميع حقوقي القانونية واتحمل المسؤولية الكاملة عن أفعالي في جميع الفعاليات التي ستعقد في حلبة كورنيش جدة (JCC ) وحضوري الفعاليات في حلبة كورنيش جدة (JCC) وجميع المرافق الأخرى ذات الصلة، وأقر أن شركة رياضة المحركات السعودية (SMC) لن تتحمل أي مسؤولية قانونية عن أي إصابات تحصل داخل الحلبة أو في مرافقها.</p>
                                                    <p>أقر أنا الموقع أدناه بأنني المسؤول الوحيد عن جميع المخاطر المتعلقة بالإصابة الشخصية أو تلف الممتلكات أو العواقب الضارة الأخرى الناشئة عن الاستخدام، بأي شكل من الأشكال، للمعدات أو الأجهزة أو المرافق أو الخدمات التي تمتلكها أو تشغلها أو تسمح بها الحلبة، بما في ذلك على سبيل المثال لا الحصر، استخدام المسار، سيارات السباق (بما في ذلك سيارات السباق الخاصة بـ حلبة كورنيش جدة (JCC) و  شركة رياضة المحركات السعودية (SMC)، أو من أي مسابقات ترفيه أو تمارين أو أي عمليات أو أنشطة يتم إجراؤها أو تنظيمها إما من قبل الحلبة أو المنظمات الأخرى. أتعهد بموجب هذا بأنني سأعفي شركة رياضة المحركات السعودية (SMC) ومراقبها الفعلي أو الشركات التابعة لها  (بما في ذلك أي خلف أو وريث لها) ومساهميها ومديريها ومسؤوليها وأعضائها وموظفيها وممثليها من أي مسؤولية أو مطالبات أو دعوى قد أوجهها عن الإصابات أو الأضرار الناشئة عن مشاركتي في حلبة كورنيش جدة ، حتى لو كان ذلك بسبب الإهمال من طرفهم، واقر انه لن تكون شركة رياضة المحركات السعودية (SMC) مسؤولة عن أي أضرار مباشرة، أو غير مباشرة ،أو تبعية، أو خاصة، أو تحذيرية، أو غيرها.</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className='row mt-3 form-group'>
                                            <input type="checkbox" {...register('acceptTerms', { required: true })} id="acceptTerms"/>
                                            <label htmlFor="acceptTerms" className='pl-2 pr-2 mb-0'>{currentLang === 'en-US'? 'Accept the waiver terms & conditions' : 'يرجى الموافقة على شروط وأحكام استمارة الإعفاء من المسؤولية'}</label>
                                            <div className='col-md-12 p-0'>{errors.acceptTerms && <span className="error-message">Please accept the waiver terms</span>}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 d-flex form-submit-button mt-5'>
                                <button type='submit' className='btn-primary form-btn' disabled={success}>
                                    {currentLang === 'en-US'? (
                                        <span>{loading? 'Submitting...' : 'Submit'}</span> 
                                    ): (
                                        <span>تسجيل</span> 
                                    )}
                                    <i className='right-arrow-icon'></i>
                                </button>
                            </div>
                        </div>
                    </form>
                )}
                {success && (
                    <div className="row h-100">
                        <div className='col-md-4 mx-auto'>
                            <div className='success-box' ref={successMesssgeRef}>
                            <div className='text-center'>
                                <img src={successIcon} alt="success-icon"/>
                                <p>{currentLang === 'en-US'? 'Submitted successfully': 'تم التسجيل بنجاح'}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default JccFitRegisterForm;
