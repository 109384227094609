import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'react-image-lightbox/style.css';
import 'react-tabs/style/react-tabs.css';
import 'assets/styles/main.scss';
import 'animate.css';
import { Footer, Navbar } from 'components';

const Layout = ({ children, pageContext, menuItems, headerItems, topMenuItems }) => {
  return pageContext ? (
    <>
      <div className='site' id={pageContext.news ? pageContext.news : pageContext.slug}>
        {/* <HeaderTopPanel slug={pageContext.slug} topMenuItems={topMenuItems} /> */}
        <Navbar menuItems={menuItems} topMenuItems={topMenuItems} headerItems={headerItems} slug={pageContext.slug} />
        <div className='wrapper' id='full-width-page-wrapper' style={{ paddingTop: "80px" }}>
          <main className='site-main' id='main' role='main'>
            {children}
          </main>
        </div>
        <Footer />
      </div>
    </>
  ) : (
    <>Loading...</>
  );
};

export default Layout;
