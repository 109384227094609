import React, { useContext } from 'react';
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import Slider from 'react-slick';
import { LanguageContext } from 'context';

const Gallery = ({ component }) => {
  const { mainTitle, images } = component;
  const currentLang = useContext(LanguageContext).currentLang;

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button type='button' data-role='none' className={className} onClick={onClick}>
        <i className='right-arrow-icon'></i>
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button type='button' data-role='none' className={className} onClick={onClick}>
        <i className='right-arrow-icon'></i>
      </button>
    );
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    nextArrow: <NextArrow className='next-arrow' />,
    prevArrow: <PrevArrow className='prev-arrow' />,
    rtl: rtl_slick(),

    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false;
  }

  return (
    <section className='gallery-area'>
      <div className='sec-title'>
        <i className='white pattern'></i>
        <h1 className='white'>{mainTitle}</h1>
      </div>
      <Slider {...sliderSettings} className='gallery-slider'>
        {images?.map((item, index) => {
          const { id, image } = item;
          const galleryImage = getImage(image);
          const bgImage = convertToBgImage(galleryImage);
          return (
            <BackgroundImage
              key={id}
              className='gallery-photo'
              Tag='div'
              {...bgImage}
              preserveStackingContext
            ></BackgroundImage>
          );
        })}
      </Slider>
    </section>
  );
};

export default Gallery;
