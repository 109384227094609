import React, { useEffect } from 'react';

const StacklaWidget = ({ mainTitle, subtitle }) => {
  useEffect(() => {
    (function (d, id) {
      var t,
        el = d.scripts[d.scripts.length - 1].previousElementSibling;
      if (el) el.dataset.initTimestamp = new Date().getTime();
      t = d.createElement('script');
      if (d.getElementById(id)) {
        d.getElementsByTagName('head')[0].removeChild(d.getElementById(id));
      }
      t.src = '//assetscdn.stackla.com/media/js/widget/fluid-embed.js';
      t.id = id;
      (d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0]).appendChild(t);
    })(document, 'stackla-widget-js');
  }, []);

  return (
    <div className='stackla-widget-wrapper container'>
      <div
        className='stackla-widget'
        data-ct=''
        data-hash={mainTitle}
        data-id={subtitle}
        data-title='Instagram Saudi Arabian GP (Website)'
        data-ttl='60'
        style={{ width: `100%`, overflow: `hidden`, marginTop: '30px' }}
      ></div>
    </div>
  );
};

export default StacklaWidget;
