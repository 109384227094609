import React from 'react';

function FeedModal({ props, showModal, setShowModal }) {
  const { mainTitle, image, blocks } = props;
  return (
    <div className={`feed-modal ${!showModal ? 'd-none' : ''}`}>
      <div className='feed-box'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          aria-hidden='true'
          className='close-icon'
          onClick={() => setShowModal(false)}
        >
          <path
            fillRule='evenodd'
            d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z'
            clipRule='evenodd'
          ></path>
        </svg>
        <img className='main-image' src={image && image.file.url} loading='lazy' alt='' />
        <div className='content'>
          <h2>{mainTitle}</h2>
          <p>{blocks}</p>
        </div>
      </div>
    </div>
  );
}

export default FeedModal;
