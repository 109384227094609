import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const OpeningClosingCeremonies = ({ component }) => {
  const { mainTitle, description, mainImage } = component;
  const pathImage = getImage(mainImage);

  return (
    <Controller>
      <section className='opening-closing-area' id='opening-wrapper'>
        <div className='image-wrapper'>
          <GatsbyImage image={pathImage} alt={mainImage.description} loading='lazy' className='main-image' />
        </div>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-7 offset-md-5'>
              <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#opening-wrapper' reverse={true}>
                <div className='content-box animate__animated'>
                  <h1 className='main-title'>{mainTitle}</h1>
                  {description && <p className='description'>{description.description}</p>}
                </div>
              </Scene>
            </div>
          </div>
        </div>
      </section>
    </Controller>
  );
};

export default OpeningClosingCeremonies;
