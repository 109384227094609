import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LanguageContext } from 'context';
import remarkGfm from "remark-gfm";

const Article = ({ article }) => {
  const currentLang = useContext(LanguageContext).currentLang;
  const { title, articleDate, articleContent, articleImage, articleDescription } = article;
  const image = getImage(articleImage);

  let dateArabic = new Date(articleDate).toLocaleDateString('ar-u-nu-latn', {
    year: 'numeric',
    day: '2-digit',
    month: 'long',
  });

  return (
    <article className='news_articles type-news_articles'>
      <header className='entry-header'>
        <div className='container-fluid news-header'>
          <section id='news-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className='entry-meta'>
                    <span className='posted-on'>
                      <time
                        className='entry-date published'
                        dateTime={currentLang === 'ar-SA' ? dateArabic : articleDate}
                      >
                        {currentLang === 'ar-SA' ? dateArabic : articleDate}
                      </time>
                    </span>
                  </div>
                  <h1 className='entry-title'>{title}</h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </header>
      <main>
        <section id='news-single-main'>
          <div className='container'>
            <div className='row'>
              <figure>
                <GatsbyImage image={image} alt={articleImage.description} />
              </figure>
              <div className='entry-content text-justify'>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{articleContent.articleContent}</ReactMarkdown>
              </div>
            </div>
          </div>
        </section>
      </main>
    </article>
  );
};

export default Article;
