import React, { useContext } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { LanguageContext } from 'context';
import { translateData } from 'utils';

const OngoingEvents = ({ component }) => {
  const { event } = component;
  const { eventName, slug, eventDescription, eventMainImage } = event;
  const dictionary = useContext(LanguageContext).staticData;
  const currentLang = useContext(LanguageContext).currentLang;
  const linksInfo = translateData(dictionary);
  const image = getImage(eventMainImage);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <BackgroundImage className='ongoing-events' Tag='section' {...bgImage}>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5 offset-md-7'>
                <span className='short-title'>{linksInfo['ongoing-events']}</span>
                <h1 className='event-name'>{eventName}</h1>
                {eventDescription && <p className='event-description'>{eventDescription.eventDescription}</p>}
                <Link className='btn-primary btn-white-hover' to={currentLang === 'en-US' ? `/en/${slug}` : `/${slug}`}>
                  <span>{linksInfo['learn-more']}</span>
                  <i className='right-arrow-icon'></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <img className='top-pattern' src={topPattern} alt='' />
        <img className='bottom-pattern' src={bottomPattern} alt='' /> */}
      </BackgroundImage>
    </>
  );
};

export default OngoingEvents;
