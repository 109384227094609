const formatTheDate = (startDate, endDate, currentLang) => {
  if (!startDate && !endDate) {
    return '';
  }
  const startDay = new Date(startDate).getDate();
  const monthStart =
    currentLang === 'ar-SA'
      ? new Date(startDate).toLocaleString('ar-KA', { month: 'long' })
      : new Date(startDate).toLocaleString('en-US', { month: 'long' });
  const yearStart = new Date(startDate).getFullYear();

  if (!endDate && startDate) {
    return `${startDay} ${monthStart}, ${yearStart}`;
  }

  const endDay = new Date(endDate).getDate();
  const monthEnd =
    currentLang === 'ar-SA'
      ? new Date(endDate).toLocaleString('ar-KA', { month: 'long' })
      : new Date(endDate).toLocaleString('en-US', { month: 'long' });
  const yearEnd = new Date(endDate).getFullYear();
  if (yearStart !== yearEnd && monthStart !== monthEnd) {
    return `${startDay} ${monthStart}, ${yearStart} - ${endDay} ${monthEnd}, ${yearEnd}`;
  }

  return `${startDay} - ${endDay} ${monthEnd}, ${yearEnd}`;
};

export default formatTheDate;
