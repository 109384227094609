import React, { useContext } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { LanguageContext } from 'context'
import { translateData } from 'utils'
import { Button } from 'components'
import pattern from '../../assets/images/teams-pattern.svg'

const TeamCarousel = ({ component }) => {
  const { subHeadTitle, mainTitle, teamCarousel, button } = component
  const currentLang = useContext(LanguageContext).currentLang
  const dictionary = useContext(LanguageContext).staticData
  const driverInfo = translateData(dictionary)

  const NextArrow = (props) => {
    const { onClick } = props
    return <button type='button' data-role='none' className='next-arrow' onClick={onClick}></button>
  }

  const PrevArrow = (props) => {
    const { onClick } = props
    return <button type='button' data-role='none' className='prev-arrow' onClick={onClick}></button>
  }

  const driverStatChange = (oldIndex, newIndex) => {
    const driverNamePlates = document.querySelectorAll('.driver-slide .driver-name')
    driverNamePlates[oldIndex].classList.add('hide')
    driverNamePlates[newIndex].classList.remove('hide')
  }

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    swipe: false,
    cssEase: 'linear',
    fade: false,
    centerMode: true,
    centerPadding: '260px',
    beforeChange: driverStatChange,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? 1 : 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 991,
        settings: {
          centerPadding: '230px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 550,
        settings: {
          centerPadding: '10px',
        },
      },
    ],
  }

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false
  }

  return (
    <section className='drivers-tabs-area'>
      <img className='top-pattern teams-pattern' src={pattern} alt='' />
      <div className='pattern-bg'></div>
      <div className='container'>
        <div className='header-wrapper'>
          <div className='sec-title light'>
            <i className='pattern'></i>
            <h1>{mainTitle}</h1>
          </div>
          {button && <Button type='teamsandDriversInfoSMC' btn={button} className='btn-primary bg-transparent' />}
        </div>
        <div className='content-wrapper'>{subHeadTitle && <p className='description'>{subHeadTitle}</p>}</div>
        <Tabs>
          <TabList className='tab-list list-unstyled' role='tablist'>
            {teamCarousel?.teams.map((team) => {
              const { id, teamName } = team
              return (
                <Tab className='nav-item' key={id} selectedClassName='active'>
                  <span>{teamName}</span>
                  <span className='rt-arrow'></span>
                </Tab>
              )
            })}
          </TabList>
          {teamCarousel?.teams.map((team, index) => {
            const { id, teamName, position, aboutTheTeam, results, teamDrivers } = team
            const sortedDrivers = teamDrivers?.sort((a, b) => b.driverPoints - a.driverPoints).slice(0, 3)
            const driversDirection = currentLang === 'ar-SA' ? [...sortedDrivers].reverse() : sortedDrivers
            return (
              <TabPanel key={id} className='section-content' selectedClassName='active'>
                <div className='row'>
                  <div className='col-lg-4'>
                    {
                      <div key={id} className={`driver-data-wrapper`}>
                        <div className='driver-data mb-3'>
                          <div className='data-name team-name'>{teamName}</div>
                          <div className='data-content team-position'>{position}</div>
                        </div>
                        {aboutTheTeam && (
                          <div className='driver-data'>
                            <p className='data-content'>{aboutTheTeam.aboutTheTeam}</p>
                          </div>
                        )}
                        {results && (
                          <div className='driver-data d-block'>
                            <div className='data-name mb-2'>{driverInfo['results']}</div>
                            <div className='data-content'>{results}</div>
                          </div>
                        )}
                        {teamDrivers && (
                          <div className='driver-data d-block'>
                            <div className='data-name mb-2'>{driverInfo['drivers']}</div>
                            {teamDrivers.map((driver) => {
                              const { id, driverName } = driver
                              return (
                                <p key={id} className='data-content mb-0'>
                                  {driverName}
                                </p>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    }
                  </div>
                  <div className='col-lg-8'>
                    <Slider {...sliderSettings} className='driver-pics'>
                      {sortedDrivers?.map((item, index) => {
                        const { id, driverName, driverProfilePicture } = item
                        const pathToImage = getImage(driverProfilePicture)

                        return (
                          <div className='driver-slide' key={id}>
                            {currentLang === 'ar-SA' && (
                              <h3 className={`driver-name ${index !== driversDirection.length - 1 ? 'hide' : ''}`}>
                                <span>{driverName}</span>
                              </h3>
                            )}
                            {currentLang === 'en-US' && (
                              <h3 className={`driver-name ${index !== 0 ? 'hide' : ''}`}>{driverName}</h3>
                            )}
                            <GatsbyImage image={pathToImage} alt={driverName} className='driver-image' />
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
              </TabPanel>
            )
          })}
        </Tabs>
      </div>
    </section>
  )
}

export default TeamCarousel
