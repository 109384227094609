import React, { useContext } from 'react'
import { LanguageContext } from 'context'
import { translateData } from 'utils'
import { ArticleCategory } from 'components'

const AllNews = ({ component }) => {
  const dictionary = useContext(LanguageContext).staticData
  const newsInfo = translateData(dictionary)

  const { article: newsArticles, slug } = component.articles
  const sortedArticles = [...newsArticles].sort((a, b) => (new Date(b.articleDate) > new Date(a.articleDate) ? 1 : -1))
  return (
    <article className='all-news-wrapper'>
      <div className='container-fluid news-header'>
        <section id='news-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='l-transp'>{newsInfo['smc-news']}</h1>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className='container-fluid'>
        <section id='news-main'>
          <div className='container'>
            <div className='row'>
              {sortedArticles.map((item) => {
                if (item.title === ' ') {
                  return null
                }
                return (
                  <div key={item.id} className='col-md-4'>
                    <ArticleCategory article={item} key={item.id} newsCategory={slug} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}

export default AllNews
