import React from 'react';

const PromoCard = ({ mainTitle, subTitle, blocks, resellerAddress }) => {
  return (
    <div className='promotion-wrapper'>
      <a href={resellerAddress?.website ? resellerAddress.website : ''} target='_blank' rel="noreferrer">
        {blocks && blocks}
        <footer>
          {mainTitle && <h1 className='main-title'>{mainTitle}</h1>}
          {subTitle && <h5 className='sub-title'>{subTitle}</h5>}
        </footer>
      </a>
    </div>
  );
};

export default PromoCard;
