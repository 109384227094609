import React, { useEffect } from 'react';

const JourneyToJccFit = ({ component }) => {
  const rawContent = JSON.parse(component.content.raw);
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);
  const [wrapperRef, setWrapperRef] = React.useState(null);

  useEffect(() => {
    const handler = () => {
      if (wrapperRef) {
        setWrapperWidth(wrapperRef.clientWidth);
      }
    };

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [wrapperRef]);

  const handlePrev = () => {
    setActiveStepIndex((prev) => (prev === 0 ? steps.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setActiveStepIndex((prev) => (prev === steps.length - 1 ? 0 : prev + 1));
  };

  // Extract steps
  const steps = rawContent.content
    .map((paragraph) => {
      if (paragraph.nodeType === 'paragraph') {
        const stepText = paragraph.content[0].value.trim(); // Get the text content of the paragraph

        // Split the step text into step number and title
        const [stepNumber, stepTitle] = stepText.split(':');

        return {
          step: stepNumber.trim(),
          title: stepTitle.trim(),
        };
      }
      return null; // Skip non-paragraph nodes if any
    })
    .filter(Boolean); // Filter out null values

  return (
    <div className='jcc-fit-journey-section'>
      <div className='container'>
        <div class='sec-title title-section' style={{ textAlign: 'center' }}>
          <i class='light pattern'></i>
          <h1 class='light'>{component?.mainTitle}</h1>
        </div>

        <div className='journey-map-wrapper'>
          <div className='journey-map' ref={setWrapperRef}>
            <div className='aspect-ratio'></div>

            <div className='step-title'>{steps && steps[activeStepIndex].title}</div>

            <div className='steps-page'>
              {activeStepIndex + 1} / {steps.length}
            </div>

            {steps &&
              steps.map((step, index) => {
                const isActive = activeStepIndex === index;
                let angle = (360 / steps.length) * (steps.length - index) - 180;
                angle -= 30;
                const x = Math.sin((angle * Math.PI) / 180) * (wrapperWidth / 2);
                const y = Math.cos((angle * Math.PI) / 180) * (wrapperWidth / 2);
                const translate = `translate(${x}px, ${y}px)`;

                return (
                  <div
                    className={`step-count ${isActive ? 'active' : ''}`}
                    style={{
                      transform: isActive ? `${translate} scale(1.5)` : translate,
                    }}
                    onClick={() => setActiveStepIndex(index)}
                    tabIndex={-1}
                    role='button'
                  >
                    <span>{step.step}</span>
                  </div>
                );
              })}
          </div>

          <div className='steps-arrows'>
            <button className='btn-primary prev-btn' type='button' data-role='none' onClick={handlePrev}>
              <i className='right-arrow-icon'></i>
            </button>

            <button className='btn-primary next-btn' type='button' data-role='none' onClick={handleNext}>
              <i className='right-arrow-icon'></i>
            </button>
          </div>
        </div>
      </div>
      {/* <img className='bottom-pattern' src={pattern} alt='' /> */}
    </div>
  );
};

export default JourneyToJccFit;
