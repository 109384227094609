import React, { useContext } from 'react';
import Slider from 'react-slick';
import { LanguageContext } from '../../context';

const settings = {};

const FerrariEventSchedule = ({ component }) => {
  const currentLang = useContext(LanguageContext).currentLang;

  const gallery = currentLang === 'ar-SA' ? component.arSchedule : component.enSchedule;

  return (
    <section className='ferrari-festival-schedule'>
      <div className='container'>
        <h2>{component.title}</h2>
        <div className='schedule-images'>
          {gallery.images.map((item, index) => {
            return (
              <a href={item.image.file.url} target='_blank' key={index}>
                <img src={item.image.file.url} />
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FerrariEventSchedule;
