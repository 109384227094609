import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const EnLogo = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulWebHeaderNavbar(node_locale: { eq: "en-US" }) {
        logoImage {
          description
          file {
            url
          }
        }
      }
    }
  `);

  const logoImage = data.contentfulWebHeaderNavbar.logoImage;

  return (
    <div>
      <img
        width='204'
        height={65}
        src={logoImage.file.url}
        className='img-fluid white-logo'
        alt={logoImage.description}
      />
    </div>
  );
};

export default EnLogo;
