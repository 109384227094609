import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const ZoomedImage = ({ contentImages }) => {
  const img = contentImages[0];
  const image = getImage(img);

  return (
    <TransformWrapper>
      <TransformComponent>
        <GatsbyImage image={image} alt={img.description || ''} className='content-image' imgClassName='content-img' />
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomedImage;
