import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const VenueMap = ({ component }) => {
  const { mainTitle, image, pdfFile, downloadFileText } = component;
  const imageBanner = getImage(image);

  return (
    <section className='venue-map-area'>
      <div className='container'>
        <div className='header-wrapper'>
          <div className='sec-title'>
            <i className='pattern'></i>
            <h1>{mainTitle}</h1>
          </div>
          <a target='_blank' download='file' href={pdfFile.file.url} className='btn-primary bg-transparent' rel="noreferrer">
            <span>{downloadFileText}</span>
            <i className='right-arrow-icon'></i>
          </a>
        </div>
      </div>
      <GatsbyImage image={imageBanner} alt={mainTitle} />
    </section>
  );
};

export default VenueMap;
