import React, { useEffect, useState, useCallback, useContext } from 'react';
import { LanguageContext } from 'context';

const CountdownTimer = ({ targetDate }) => {
  const currentLang = useContext(LanguageContext).currentLang;
  const isArabic = currentLang === 'ar-SA';

  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const timeLeft = targetDate.getTime() - now.getTime();

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient) return;

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [isClient, calculateTimeLeft]);

  if (!isClient) {
    // Render an empty fragment or placeholder
    return null;
  }

  const translations = {
    en: {
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
    },
    ar: {
      days: 'أيام',
      hours: 'ساعات',
      minutes: 'دقائق',
      seconds: 'ثواني',
    },
  };

  const currentTranslations = translations[isArabic ? 'ar' : 'en'];

  return (
    <div className="d-flex flex-wrap justify-content-center gap-3 p-3 countdown-timer">
      {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
        <div key={unit} className="d-flex flex-column align-items-center rounded shadow-lg p-3" style={{ minWidth: '100px' }}>
          <div className="position-relative w-100 text-center">
            <div
              className="position-absolute w-100"
              style={{
                top: '55%',
                height: '75%',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
              }}
            ></div>
            <div className="position-relative">
              <span className="fs-2">{timeLeft[unit]}</span>
            </div>
          </div>
          <span className="mt-3">{currentTranslations[unit].toUpperCase()}</span>
        </div>
      ))}
    </div>
  );
};

export default CountdownTimer;
