import React, { useContext } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { CustomForm } from 'components';
import { translateData } from 'utils';
import { LanguageContext } from 'context';
import { Link } from 'gatsby';

const MailChimpForm = ({ component }) => {
  const { description, mainTitle, successfulRegistration, postUrl: mailChimpsUrl } = component;
  const currentLang = useContext(LanguageContext).currentLang;
  const dictionary = useContext(LanguageContext).staticData;
  const dictionaryItems = translateData(dictionary);

  return (
    <>
      <div className='container-fluid'>
        <section id='stay-on-track'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <h2>{mainTitle}</h2>
                <p>{description.description}</p>
              </div>
              <div className='col-md-6 col-sm-12'>
                <MailchimpSubscribe
                  url={mailChimpsUrl}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      successfulRegistration={successfulRegistration}
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
                <p className='terms-link'>
                  {dictionaryItems['form-subscribing-text']}{' '}
                  <Link to={`${currentLang === 'ar-SA' ? '/terms-and-conditions' : '/en/terms-and-conditions'}`}>
                    {dictionaryItems['link-terms-use']}
                  </Link>{' '}
                  {dictionaryItems['form-subscribing-and']}{' '}
                  <Link to={`${currentLang === 'ar-SA' ? '/privacy-policy' : '/en/privacy-policy'}`}>
                    {dictionaryItems['link-privacy-policy']}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MailChimpForm;
