import React from 'react';

const ResellerCard = ({ cssWrapper, subTitle, mainTitle, resellerAddress }) => {
  const { phone, email1, email2, website } = resellerAddress;

  return (
    <div className='reseller-col'>
      <div className='reseller-card'>
        <h2 className='main-title'>{mainTitle}</h2>
        <p className='sub-title'>{subTitle}</p>
        <ul className='list-unstyled pl-0 pr-0'>
          {phone && (
            <li>
              <i className='phone-icon'></i>
              <span>
                <a href={`tel:${phone.trim()}`}>{phone}</a>
              </span>
            </li>
          )}
          {email1 && (
            <li>
              <i className='email-icon'></i>
              <span>
                <a href={`mailto:${email1.trim()}`}>{email1}</a>
              </span>
            </li>
          )}
          {email2 && (
            <li>
              <i className='email-icon'></i>
              <span>
                <a href={`mailto:${email2.trim()}`}>{email2}</a>
              </span>
            </li>
          )}
          {website && (
            <li>
              <i className='website-icon'></i>
              <span>
                <a href={`https://${website.trim()}`} target='_blank' rel="noreferrer">
                  {website}
                </a>
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ResellerCard;
