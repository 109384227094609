import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const SponsorCarousel = ({ component, wrapper = '' }) => {
  const sponsors = component.sponsorsItems; // all sponsors
  const isFollowUsSectionDakar = 
  component?.title === "Follow us for more information" || 
  component?.title === "تابعونا للمزيد من المعلومات";
  return (
    <section className={isFollowUsSectionDakar ? 'sponsors-area-dakar' : 'sponsors-area'} style={isFollowUsSectionDakar ? { backgroundColor: '#F8C602' } : {}}>
      <div className='sponsors-container'>
        {
          isFollowUsSectionDakar && <h1 className='d-flex justify-content-center pb-5'>{component?.title}</h1>
        }
        
        <div className={`sponsors-list ${isFollowUsSectionDakar && 'd-flex flex-column align-items-center justify-content-center gap-4 px-2 flex-lg-row'}`}>
          {sponsors.map((item) => {
            const { id, logoImage, link } = item;
            const image = getImage(logoImage);
            return (
              <a href={link} target='_blank' key={id} className='sponsor-box'>
                <GatsbyImage
                  image={image}
                  alt={logoImage.description}
                  className='sponsor-image'
                  style={logoImage.description === 'Protomin' ? { width: '250px' } : {}}
                />
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SponsorCarousel;