import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useContext } from 'react';
import { LanguageContext } from 'context';
import { BLOCKS } from '@contentful/rich-text-types';

const HomeHero = ({ component, wrapper }) => {
  const currentLang = useContext(LanguageContext).currentLang;
  const content = documentToReactComponents(JSON.parse(component.description.raw), {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.[0] === '') return;
        return <p>{children}</p>;
      },
    },
  });
  const isCarsAndGahwaSection = component?.type === 'cars-gahwa';
  return (
    <section
      className='hero-section relative'
      style={{
        backgroundImage: `url(${component.image.file.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='container'>
        <div className='hero-content'>
          {component?.subtitle && <div className='subtitle'>{component.subtitle}</div>}
          <h1>
            <span className='relative'>{component.title}</span>
          </h1>
          <p className={`text-justify ${isCarsAndGahwaSection ? 'description-cars-gahwa' : 'description'}`}>
            {content}
          </p>

          {component?.locationLink && (
            <div className='description-cars-gahwa'>
              {currentLang === 'ar-SA' ? 'الموقع' : 'Location'}:
              <a href={component.locationLink.link} className='mx-2 text-white'>
                {component.locationLink.label}
              </a>
            </div>
          )}

          {component?.button && (
            <a href={component.button.link} className='secondary-btn btn-lg'>
              {component.button.label}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
