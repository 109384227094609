import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import FeedModal from './FeedModal';

const WebRowLayoutWithAboutEvent = ({ component }) => {
  const [showModal, setShowModal] = useState(false);
  const [props, setProps] = useState({});

  return (
    <>
      <section className='about-event-area'>
        <div className='container'>
          <div className='sec-title' style={{ marginBottom: '50px' }}>
            <i className='pattern'></i>
            <h1>{component.mainTitle}</h1>
          </div>
          <div className='row'>
            {component.aboutEventsSections.map((items) => {
              const { id, mainTitle, description, image } = items;
              const options = {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => {
                    if (children[0] === '') return;
                    return <p>{children}</p>;
                  },
                },
              };
              let descriptionText;
              if (description) {
                descriptionText = JSON.parse(description.raw);
              }

              let blocks;
              if (descriptionText) {
                blocks = documentToReactComponents(descriptionText, options);
              }
              return (
                <>
                  {/* <div className='d-md-flex align-items-center dakar-rows' key={id}>
            <img
              className='main-image'
              src={image.file.url}
              alt={image.description || ''}
              loading='lazy' />
            <div className='layout-content'>
              <h2>{mainTitle}</h2>
              <p>{blocks}</p>
            </div>
          </div> */}

                  <div
                    className='col-md-4'
                    key={id}
                    onClick={() => {
                      setProps({
                        mainTitle,
                        blocks,
                        image,
                      });
                      setShowModal(true);
                    }}
                  >
                    <div className='position-relative dakar-img-bg' style={{ height: 235 }}>
                      <div
                        className='position-absolute w-100 h-100'
                        style={{ backgroundImage: `url(${image.file.url})`, backgroundSize: 'cover' }}
                      ></div>
                      <h4 className='dakar-name text-white z-index-1 position-absolute'>{mainTitle}</h4>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      <FeedModal props={props} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default WebRowLayoutWithAboutEvent;
