import React from 'react';

const SaudiExperience = ({ component }) => {
  const { image } = component;

  return (
    <section>
      <img style={{ width: "100%" }} src={image.file.url} alt='Saudi Experience' width={1440} />
    </section>
  );
};

export default SaudiExperience;
