import React, { useContext } from 'react'
import classNames from 'classnames'
import { LanguageContext } from 'context'

const lemniskEventType = {
  'smc-home': 'homePanelClickedSMC',
  'jeddah-cornich-circuit': 'raceCircuitClickedSMC',
  events: 'eventsClickedSMC',
  'smc-news': 'newsPanelClickedSMC',
  wtcr: 'wtcrRaceClickedSMC',
  dakar: 'dakarInfoSMC',
  magazine: 'smcMagazineTabClicked',
}

const MenuItem = ({ page, onCloseMenu, onToggleSubMenu, isSubMenuClose }) => {
  const currentLang = useContext(LanguageContext).currentLang
  const subMenu = page.subPages

  let localePath
  if (currentLang === 'ar-SA') {
    localePath = ''
  } else {
    localePath = 'en/'
  }

  const showSubMenuClass = classNames({
    'sub-menu': true,
    'open-menu': isSubMenuClose,
  })

  const openButtonClass = classNames({
    open: isSubMenuClose,
  })

  const { gtAction, gtEvent, gtEventCategory, gtLabel } = page

  let pageSlug = page.slug
  if (pageSlug === 'smc-home') {
    pageSlug = ''
  }

  const getAnalytics = () => {
    if (gtLabel && gtEvent && gtAction && gtEventCategory) {
      window.dataLayer.push({
        event: `${gtEvent}`,
        event_category: `${gtEventCategory}`,
        event_action: `${gtAction}`,
        event_label: `${gtLabel}`,
        destination_url: `${window.location.hostname}/${localePath}${pageSlug}`,
      })
    }
  }

  const handleClick = (event, item) => {
    event.stopPropagation()
    event.preventDefault()
    if (lemniskEventType[item.slug]) {
      window.lmSMTObj.track(lemniskEventType[item.slug], {
        label: `${item.title}`,
      })
    }
    if (gtLabel && gtEvent && gtAction && gtEventCategory) {
      window.dataLayer.push({
        event: `${gtEvent}`,
        event_category: `${gtEventCategory}`,
        event_action: `${gtAction}`,
        event_label: `${gtLabel}`,
        destination_url: `${window.location.hostname}/${localePath}${item.slug}`,
      })
    }
    window.open(`/${localePath}${item.slug}`, '_self')
  }
  const pageClick = (event, item) => {
    event.stopPropagation()
    event.preventDefault()

    let pageSlug = page.slug
    if (pageSlug === 'smc-home') {
      pageSlug = ''
    }

    if (lemniskEventType[page.slug]) {
      page.slug === 'magazine'
        ? window.lmSMTObj.track(lemniskEventType[page.slug], { "magazineLanguage": currentLang === 'ar-SA' ? "Arabic" : "English" })
        : window.lmSMTObj.track(lemniskEventType[page.slug], { label: `${item.title}`});
    }

    onCloseMenu()
    getAnalytics()

    window.open(`/${localePath}${pageSlug}`, '_self')
  }

  return (
    <li className='menu-item'>
      <a
        title={page.title}
        // to={`/${localePath}${pageSlug}`}
        className='nav-link'
        // onClick={(onCloseMenu, getAnalytics, handleClick)}
        href={'javascript:void(0)'}
        onClick={(event) => pageClick(event, page)}
        getProps={({ isCurrent }) => {
          return isCurrent ? { className: 'nav-link active' } : null
        }}
      >
        {page.title}
      </a>
      {subMenu && (
        <>
          <button onClick={onToggleSubMenu} type='button' aria-label='Menu toggle' className={openButtonClass}></button>
          <ul className={showSubMenuClass}>
            {subMenu.map((subPage) => {
              const { gtLabel, gtEvent, gtAction, gtEventCategory, title, link } = subPage
              return (
                <li key={subPage.id}>
                  {link ? (
                    <a
                      href={link}
                      className='nav-link'
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={() => {
                        if (gtLabel && gtEvent && gtAction && gtEventCategory) {
                          window.dataLayer.push({
                            event: `${gtEvent}`,
                            event_category: `${gtEventCategory}`,
                            event_action: `${gtAction}`,
                            event_label: `${gtLabel}`,
                            destination_url: `${link}`,
                          })
                        }
                      }}
                    >
                      {title}
                    </a>
                  ) : (
                    <a
                      // to={`/${localePath}${slug}`}
                      className='nav-link'
                      getProps={({ isPartiallyCurrent }) => {
                        return isPartiallyCurrent ? { className: 'nav-link active' } : null
                      }}
                      href={'javascript:void(0)'}
                      onClick={(event) => handleClick(event, subPage)}
                      // onClick={() => {
                      //   if (gtLabel && gtEvent && gtAction && gtEventCategory) {
                      //     window.dataLayer.push({
                      //       event: `${gtEvent}`,
                      //       event_category: `${gtEventCategory}`,
                      //       event_action: `${gtAction}`,
                      //       event_label: `${gtLabel}`,
                      //       destination_url: `${window.location.hostname}/${localePath}${slug}`,
                      //     })
                      //   }
                      // }}
                    >
                      {title}
                    </a>
                  )}
                </li>
              )
            })}
          </ul>
        </>
      )}
    </li>
  )
}

export default MenuItem
