import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const DakarRoute = ({ component }) => {
  const { title, image } = component;
  const img = getImage(image);
  return (
    <section className='dakar-route' style={{ marginBottom: '100px' }}>
      <div className='sec-title container' style={{ marginBottom: '50px' }}>
        <i className='pattern'></i>
        <h1>{title}</h1>
      </div>
      {/* <GatsbyImage image={img} /> */}
      <GatsbyImage
        image={img}
        alt="Dakar image"
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '80%'
        }}
      />
    </section>
  );
};

export default DakarRoute;
