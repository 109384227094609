import React, { useContext, useEffect } from 'react';
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import Slider from 'react-slick';
import { LanguageContext } from 'context';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const DesertXpCarousel = ({ component }) => {
  const { items } = component;

  const currentLang = useContext(LanguageContext).currentLang;

  if (currentLang === 'ar-SA') {
    items.reverse();
  }

  useEffect(() => {
    if (currentLang === 'ar-SA') {
      items.reverse();
    }
  }, [currentLang, items]);

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button type='button' data-role='none' className={className} onClick={onClick}>
        <i className='right-arrow-icon'></i>
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button type='button' data-role='none' className={className} onClick={onClick}>
        <i className='right-arrow-icon'></i>
      </button>
    );
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 20000,
    cssEase: 'linear',
    nextArrow: <NextArrow className='next-arrow' />,
    prevArrow: <PrevArrow className='prev-arrow' />,
    rtl: rtl_slick(),
  };

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false;
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  return (
    <section className='desert-carousel-area' dir={currentLang === 'ar-SA' ? 'rtl' : 'ltr'}>
      <Slider {...sliderSettings} className='desert-carousel'>
        {items?.map((item, index) => {
          const { id, mainTitle, content, backgroundImage } = item;
          let contentTextDocument;
          if (content) {
            contentTextDocument = JSON.parse(content.raw);
          }

          let blocks;
          if (contentTextDocument) {
            blocks = documentToReactComponents(contentTextDocument, options);
          }
          const galleryImage = getImage(backgroundImage);
          const bgImage = convertToBgImage(galleryImage);
          return (
            <div key={id} className='desert-slide'>
              <BackgroundImage Tag='div' {...bgImage} className='image-container'>
                <div className='overlay'></div>
              </BackgroundImage>

              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 offset-md-6'>
                    <div>
                      {mainTitle && <h1 className='main-title'>{mainTitle}</h1>}
                      {blocks && blocks}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default DesertXpCarousel;
