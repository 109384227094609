import React, { useContext } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { LanguageContext } from 'context'
import { translateData } from 'utils'
import { Button } from 'components'
import pattern from '../../assets/images/wtcr-tyre-pattern.svg'

const DriversCarousel = ({ component, wrapper }) => {
  const { mainTitle, carouselDescription, drivers, button } = component
  const currentLang = useContext(LanguageContext).currentLang
  const dictionary = useContext(LanguageContext).staticData
  const driverInfo = translateData(dictionary)
  const sortedDrivers2 = drivers?.drivers?.sort((a, b) => a.driverRank - b.driverRank)
  const sortedDrivers =
    wrapper === 'f1-drivers' ? sortedDrivers2 : drivers?.drivers?.sort((a, b) => b.driverPoints - a.driverPoints)
  const driversDirection = currentLang === 'ar-SA' ? [...sortedDrivers].reverse() : sortedDrivers

  const NextArrow = (props) => {
    const { onClick } = props
    return <button type='button' data-role='none' className='next-arrow' onClick={onClick}></button>
  }

  const PrevArrow = (props) => {
    const { onClick } = props
    return <button type='button' data-role='none' className='prev-arrow' onClick={onClick}></button>
  }

  const driverStatChange = (oldIndex, newIndex) => {
    const driverStatSlides = document.querySelectorAll('.driver-data-wrapper')
    const driverNamePlates = document.querySelectorAll('.driver-slide .driver-name')
    driverStatSlides[oldIndex].classList.add('hide')
    driverNamePlates[oldIndex].classList.add('hide')
    driverStatSlides[newIndex].classList.remove('hide')
    driverNamePlates[newIndex].classList.remove('hide')
  }

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    swipe: false,
    cssEase: 'linear',
    fade: false,
    centerMode: true,
    centerPadding: '260px',
    beforeChange: driverStatChange,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? driversDirection.length - 1 : 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 991,
        settings: {
          centerPadding: '230px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 550,
        settings: {
          centerPadding: '10px',
        },
      },
    ],
  }

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false
  }

  return (
    <section className='drivers-carousel-area'>
      <img className='top-pattern' src={pattern} alt='' />
      <div className='pattern-bg'></div>
      <div className='container'>
        <div className='header-wrapper'>
          <div className='sec-title light'>
            <i className='pattern'></i>
            <h1>{mainTitle}</h1>
          </div>
          <Button btn={button} type='driverInfoSMC' className='btn-primary bg-transparent' />
        </div>
        {carouselDescription && <p className='description'>{carouselDescription}</p>}
        <div className='row'>
          <div className='col-lg-4'>
            <div>
              {driversDirection?.map((driver, index) => {
                const {
                  id,
                  driverName,
                  teamTitle,
                  entryNumber,
                  driverPoints,
                  car,
                  country,
                  dateOfBirth,
                  driverRank,
                  worldChampionships,
                  highestRaceFinish,
                  highestGridPosition,
                  grandPrixEntered,
                  placeOfBirth,
                  podiums,
                } = driver
                return (
                  <div key={id} className={`driver-data-wrapper ${index !== 0 ? 'hide' : ''}`}>
                    <h1 className='driver-name'>
                      <span className='name'>{driverName}</span>
                      <span className='rank'>{driverRank}</span>
                    </h1>
                    {teamTitle && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-team']}</div>
                        <div className='data-content'>{teamTitle}</div>
                      </div>
                    )}
                    {entryNumber && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['entry-number']}</div>
                        <div className='data-content'>{entryNumber}</div>
                      </div>
                    )}
                    {driverPoints && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-points']}</div>
                        <div className='data-content'>{driverPoints}</div>
                      </div>
                    )}
                    {car && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['car']}</div>
                        <div className='data-content'>{car}</div>
                      </div>
                    )}
                    {country && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-country']}</div>
                        <div className='data-content'>{country}</div>
                      </div>
                    )}
                    {dateOfBirth && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-birth-date']}</div>
                        <div className='data-content'>{dateOfBirth}</div>
                      </div>
                    )}
                    {podiums && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-podiums']}</div>
                        <div className='data-content'>{podiums}</div>
                      </div>
                    )}
                    {grandPrixEntered && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-grand-prix']}</div>
                        <div className='data-content'>{grandPrixEntered}</div>
                      </div>
                    )}
                    {worldChampionships != null && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-world-championship']}</div>
                        <div className='data-content'>{worldChampionships}</div>
                      </div>
                    )}
                    {highestRaceFinish && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-race-finish']}</div>
                        <div className='data-content'>{highestRaceFinish}</div>
                      </div>
                    )}
                    {highestGridPosition && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-grid-position']}</div>
                        <div className='data-content'>{highestGridPosition}</div>
                      </div>
                    )}
                    {placeOfBirth && (
                      <div className='driver-data'>
                        <div className='data-name'>{driverInfo['driver-birth-place']}</div>
                        <div className='data-content'>{placeOfBirth}</div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className='col-lg-8'>
            <Slider {...sliderSettings} className='driver-pics'>
              {sortedDrivers?.map((item, index) => {
                const { id, driverName, driverProfilePicture, driverPhoto } = item
                const pathToImage = wrapper === 'f1-drivers' ? getImage(driverPhoto) : getImage(driverProfilePicture)

                return (
                  <div className='driver-slide' key={id}>
                    {currentLang === 'ar-SA' && (
                      <h3 className={`driver-name ${index !== driversDirection.length - 1 ? 'hide' : ''}`}>
                        <span>{driverName}</span>
                      </h3>
                    )}
                    {currentLang === 'en-US' && (
                      <h3 className={`driver-name ${index !== 0 ? 'hide' : ''}`}>{driverName}</h3>
                    )}
                    <GatsbyImage image={pathToImage} alt={driverName} className='driver-image' />
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DriversCarousel
