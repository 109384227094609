import React from 'react';

const Video = ({ component }) => {
  const { mainTitle, mainVideo } = component;

  return (
    <section className='video-area'>
      <div className='container'>
        <div className='sec-title'>
          <i className='white pattern'></i>
          <h1 className='white'>{mainTitle}</h1>
        </div>
      </div>
      <div className='overlay'></div>
      <video loop autoPlay muted className='video-background'>
        <source src={mainVideo.file.url} type='video/mp4' />
      </video>
    </section>
  );
};
export default Video;
