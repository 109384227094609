import React from 'react';
import { Link } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from 'react-markdown';

const NotFoundComponent = ({ component }) => {
  const { mainTitle, description, image, backgroundImage, button } = component;
  const imageBg = getImage(backgroundImage);
  const bgImage = convertToBgImage(imageBg);
  const pathImage = getImage(image);
  return (
    <BackgroundImage className='container-fluid p404-header' Tag='div' {...bgImage} preserveStackingContext>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12' id='p404-intro'>
              <figure className='animate__animated animate__fadeInUp animate__delay-1s'>
                <GatsbyImage image={pathImage} alt={image.description} loading='lazy' />
              </figure>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 d404-content-column'>
              <div className='animate__animated animate__fadeInRight animate__delay-2s d404-content'>
                <h2 className='404-title'>{mainTitle}</h2>
                <ReactMarkdown>{description.description}</ReactMarkdown>
                <Link className='btn-primary' to={button.link}>
                  <span>{button.label}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  );
};

export default NotFoundComponent;
