import React from 'react'
import { Button } from 'components'

const RaceFacts = ({ component }) => {
  const { mainTitle, factsItems, button } = component

  return (
    <section className='race-facts-area'>
      <div className='container'>
        <div className='sec-title'>
          <i className='pattern'></i>
          <h1>{mainTitle}</h1>
        </div>
        <div className='row'>
          {factsItems.map((factItem) => {
            const { id, mainTitle, description, description2 } = factItem
            return (
              <div key={id} className='col-lg-3 col-md-6'>
                <div className='fact-box'>
                  <h4 className='data-name'>{mainTitle}</h4>
                  <h2 className='data-content'>{description}</h2>
                  {description2 && description2.length > 0 && (
                    description2.map((desc, index)=> (
                      <h2 className='data-content' key={index}>{desc}</h2>
                    ))
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {button && <Button btn={button} type={'raceFactsSMC'} className='btn-primary' />}
      </div>
    </section>
  )
}

export default RaceFacts
