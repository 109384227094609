import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { LanguageContext } from 'context';
import { translateData } from 'utils';

const HeaderTopMenu = ({ slug, topMenuItems = [], contactIsHidden, langIsHidden }) => {
  const currentLang = useContext(LanguageContext).currentLang;
  const dictionary = useContext(LanguageContext).staticData;
  const linksInfo = translateData(dictionary);
  const [footerOffsetHeight, setFooterOffsetHeight] = useState(0);

  const changeToEnglish = () => {
    document.documentElement.removeAttribute('dir', 'rtl');
    document.documentElement.removeAttribute('lang', 'ar');
    document.body.classList.remove('rtl');
  };

  const changeToArabic = () => {
    document.documentElement.setAttribute('dir', 'rtl');
    document.documentElement.setAttribute('lang', 'ar');
    document.body.classList.add('rtl');
  };

  useEffect(() => {
    const footerArea = document.querySelector('.footer-area');
    let offset = footerArea.offsetTop;
    setFooterOffsetHeight(offset);
  }, [setFooterOffsetHeight]);

  const scrollHandler = (e) => {
    window.scrollTo({
      top: footerOffsetHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <ul className='lang-switcher'>
        {topMenuItems
          .sort((a, b) => (a.slug > b.slug ? 1 : -1))
          .map((topItem) => {
            const { gtLabel, gtEvent, gtAction, gtEventCategory } = topItem;
            return (
              <li className={contactIsHidden ? 'd-none d-md-inline-block' : ''} key={topItem.slug}>
                {currentLang === 'ar-SA' && (
                  <Link
                    className='nav-link language-link'
                    to={`/${topItem.slug}`}
                    onClick={() => {
                      if (gtLabel && gtEvent && gtAction && gtEventCategory) {
                        window.dataLayer.push({
                          event: `${gtEvent}`,
                          event_category: `${gtEventCategory}`,
                          event_action: `${gtAction}`,
                          event_label: `${gtLabel}`,
                          destination_url: `${window.location.hostname}/${topItem.slug}`,
                        });
                      }
                    }}
                  >
                    {topItem.title}
                  </Link>
                )}
                {currentLang === 'en-US' && (
                  <Link
                    className='nav-link language-link'
                    to={`/en/${topItem.slug}`}
                    onClick={() => {
                      if (gtLabel && gtEvent && gtAction && gtEventCategory) {
                        window.dataLayer.push({
                          event: `${gtEvent}`,
                          event_category: `${gtEventCategory}`,
                          event_action: `${gtAction}`,
                          event_label: `${gtLabel}`,
                          destination_url: `${window.location.hostname}/en/${topItem.slug}`,
                        });
                      }
                    }}
                  >
                    {topItem.title}
                  </Link>
                )}
              </li>
            );
          })}
        <li className={contactIsHidden ? 'd-none d-md-inline-block pointer' : 'pointer'}>
          {currentLang === 'ar-SA' && (
            <span className='nav-link' onClick={scrollHandler}>
              اتصل بنا
            </span>
          )}
          {currentLang === 'en-US' && (
            <span className='nav-link' onClick={scrollHandler}>
              Contact Us
            </span>
          )}
        </li>
        <li className={langIsHidden ? 'd-none d-md-inline-block' : ''}>
          {currentLang === 'ar-SA' && (
            <Link
              to={slug ? `/en/${slug === 'smc-home' ? '' : slug}` : '/en/'}
              onClick={changeToEnglish}
              className='nav-link language-link'
            >
              <span lang='en'>{linksInfo['link-language-name']}</span>
            </Link>
          )}
          {currentLang === 'en-US' && (
            <Link
              to={slug ? `/${slug === 'smc-home' ? '' : slug}` : '/'}
              onClick={changeToArabic}
              className='nav-link language-link'
            >
              <span lang='ar'>{linksInfo['link-language-name']}</span>
            </Link>
          )}
        </li>
      </ul>
    </>
  );
};

export default HeaderTopMenu;
