import React, { useState, useEffect, useContext } from 'react';
import { validateEmail, translateData } from 'utils';
import { LanguageContext } from 'context';

const CustomForm = ({ status, message, onValidated, successfulRegistration }) => {
  const dictionary = useContext(LanguageContext).staticData;
  const formItems = translateData(dictionary);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (firstNameError || lastNameError || emailError || email === '' || firstName === '' || lastName === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [firstNameError, lastNameError, emailError, email, lastName, firstName]);

  const handleInputName = (e) => {
    setFirstName(e.target.value);
    if (e.target.value === '') {
      setFirstNameError(formItems['form-required-error']);
    } else {
      setFirstNameError('');
    }
  };

  const handleInputLastName = (e) => {
    setLastName(e.target.value);
    if (e.target.value === '') {
      setLastNameError(formItems['form-required-error']);
    } else {
      setLastNameError('');
    }
  };

  const handleInputEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value === '') {
      setEmailError(formItems['form-required-error']);
    } else if (!validateEmail(e.target.value) && e.target.value !== '') {
      setEmailError(formItems['form-email-invalid']);
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstNameError && !emailError && !lastNameError) {
      setIsFormValid(false);
      setLastName('');
      setFirstName('');
      setEmail('');
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
    }
  };

  return (
    <form id='sect-news-signup' onSubmit={(e) => handleSubmit(e)}>
      {status === 'sending' && <div className='mc__alert mc__alert--sending'>sending...</div>}
      {status === 'error' && (
        <div className='mc__alert mc__alert--error' dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {/* {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )} */}
      {status !== 'success' ? (
        <>
          <div className='mc__field-container'>
            <span>
              <input
                onChange={(e) => handleInputName(e)}
                type='text'
                placeholder={formItems['form-first-name']}
                value={firstName.trim()}
                id='name'
                name='inputName'
              />
              <label htmlFor='name'>{formItems['form-first-label']}</label>
              {firstNameError && <p className='error'>{firstNameError}</p>}
            </span>
            <span>
              <input
                onChange={(e) => handleInputLastName(e)}
                type='text'
                placeholder={formItems['form-last-name']}
                value={lastName.trim()}
                id='name'
                name='inputLastName'
              />
              <label htmlFor='name'>{formItems['form-last-label']}</label>
              {lastNameError && <p className='error'>{lastNameError}</p>}
            </span>
          </div>
          <div className='mc__field-container'>
            <span>
              <input
                onChange={(e) => handleInputEmail(e)}
                type='email'
                placeholder={formItems['form-email']}
                value={email}
                id='email'
              />
              <label htmlFor='email'>{formItems['form-email-label']}</label>
              {emailError && <p className='error'>{emailError}</p>}
            </span>
            <div>
              <input
                className='btn-primary'
                type='submit'
                value={formItems['form-submit-button']}
                disabled={!isFormValid}
              />
            </div>
          </div>
        </>
      ) : (
        <div className='confirmation_message mc__alert mc__alert--success'>
          {successfulRegistration.successfulRegistration}
        </div>
      )}
    </form>
  );
};

export default CustomForm;
