import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Controller, Scene } from 'react-scrollmagic';

const TheRoute = ({ component }) => {
  const { mainTitle, description, mainImage } = component;
  const imageBg = getImage(mainImage);
  const bgImage = convertToBgImage(imageBg);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  let descriptionText;
  if (description) {
    descriptionText = JSON.parse(description.raw);
  }

  let blocks;
  if (descriptionText) {
    blocks = documentToReactComponents(descriptionText, options);
  }

  return (
    <BackgroundImage className='route-area' id='route-wrapper' Tag='div' {...bgImage} preserveStackingContext>
      <Controller>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <Scene offset={100} classToggle='animate__fadeInUp' triggerElement='#route-wrapper' reverse={true}>
                <div className='content-box animate__animated'>
                  <h1 className='main-title'>{mainTitle}</h1>
                  {description && <div className='description'>{blocks}</div>}
                </div>
              </Scene>
            </div>
          </div>
        </div>
      </Controller>
    </BackgroundImage>
  );
};

export default TheRoute;
