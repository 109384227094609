import React, { useContext } from 'react';
import { LanguageContext } from 'context';
import Slider from 'react-slick';
import { ArriveAndDriveCard } from 'components';

const ArriveAndDrive = ({ component, wrapper = '' }) => {
  const { columns } = component;
  const currentLang = useContext(LanguageContext).currentLang;

  const rtl_slick = () => {
    return currentLang === 'ar-SA' ? true : false;
  };

  const responsiveSettings = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const defaultSlidesToShow = 3;

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: defaultSlidesToShow,
    slidesToScroll: 1,
    dots: false,
    swipe: true,
    cssEase: 'linear',
    fade: false,
    draggable: true,
    centerMode: false,
    arrows: false,
    rtl: rtl_slick(),
    // initialSlide: `${currentLang}` === 'ar-SA' ? cards.length - defaultSlidesToShow : 0,
    responsive: responsiveSettings,
  };
  return (
    <section className='latest-news'>
      <Slider {...sliderSettings} className='latest-news-slider'>
        {columns.map((component) => {
          return <ArriveAndDriveCard item={component} />;
        })}
      </Slider>
    </section>
  );
};

export default ArriveAndDrive;
